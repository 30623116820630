import React, { FC, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Modal,
  DatePicker,
  message,
} from "antd";
import { useConnection } from "../../services/ApplicationContext";
import { useBusiness } from "../../services/BuisnessService";

interface IUpdateCustomer {
  visible: boolean;
  onClose: (status?: "success" | "failed") => void;
  edit: boolean;
  data?: any;
}
const UpdateCustomer: FC<IUpdateCustomer> = (props: IUpdateCustomer) => {
  const { visible, onClose, data, edit } = props;
  const { post, patch } = useConnection();
  const { fetchCustomers } = useBusiness();

  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 14 },
  };
  const [loading, setLoading] = useState<boolean>(false);

  const onConfrim = async (values: any): Promise<void> => {
    console.log("Customer Data ", values);
    setLoading(true);
    try {
      if (edit && data) {
        const result = await patch(`customer/${data._id}`, values);
        if (result.status === 200) {
          message.open({
            type: "success",
            content: "Customer Updated!",
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
          onClose("success");
        } else {
          message.open({
            type: "warning",
            content: "Customer Update Failed!",
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
      } else {
        setLoading(true);
        const result = await post("customer", values);
        if (result.status === 201) {
          console.log("Successfull");
          fetchCustomers();
          onClose("success");
        } else {
          console.log("Failed");
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        // content: "Error occurred",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  // const onFinish = async(values: any) => {
  //   console.log(values);
  //   try{
  //     const result = await post("customer", values);
  //     if(result.status === 200){
  //       console.log("Successfull");
  //     } else{
  //       console.log("Failed");
  //     }
  //   } catch(error){
  //     console.log(error);
  //   }
  // };

  const formItemLayout = {
    labelCol: {
      span: 6,
    },
  };
  return (
    <Modal
      title={status ? `Update Customer` : `Customer`}
      centered
      visible={visible}
      onCancel={() => onClose()}
      width="80%"
      footer={null}
    >
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onConfrim}
        className="ant-advanced-search-form"
        {...formItemLayout}
        // onValuesChange={onFormChange}
        initialValues={data}
      >
        <Form.Item
          name="shopName"
          label="Studio Name"
          rules={[{ required: true, message: "Please input Studio Name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="owner"
          label="Owner Name"
          rules={[{ required: true, message: "Please input Owner Name!" }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item name={"email"} label="Email" rules={[{ type: "email" }]}>
          <Input />
        </Form.Item> */}
        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: true, message: "Please input Address!" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="contactNumber"
          label="contactNumber"
          rules={[{ required: true, message: "Please input Phone Number!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="accountBalance"
          label="Account Balance"
          rules={[{ required: true, message: "Please input Account Balance!" }]}
        >
          <InputNumber<string> style={{ width: 200 }} step="0.01" stringMode />
        </Form.Item>
        <Form.Item
          name="joinDate"
          label="Join Date"
          rules={[{ required: true, message: "Please input Date!" }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UpdateCustomer;
