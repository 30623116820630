import React, { FC } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import { Category, IItem, ItemTypesEnum } from "../../Constant/ItemModel";
import { useConnection } from "../../services/ApplicationContext";
import { renderEnumOptions } from "../../utilities/ReactUtilities";

interface ManageItemModalProps {
  visible: boolean;
  selectedItem?: IItem;
  handleCancel: (val?: "saved") => void;
}
export const MangeItemModal: FC<ManageItemModalProps> = (props) => {
  const { visible, handleCancel, selectedItem } = props;
  const [form] = Form.useForm();
  const { post, patch } = useConnection();

  const onReset = () => {
    form.resetFields();
  };
  const onFinish = async (values: any) => {
    try {
      let result;
      if (selectedItem && selectedItem._id) {
        values._id = selectedItem?._id;
        result = await patch("items", {
          ...values,
          _id: selectedItem?._id,
        });
      } else {
        result = await post("items", values);
      }
      if (result.status === 201) {
        message.open({
          type: "success",
          content: `ItemCode ${selectedItem ? "updated." : "Created."}`,
          duration: 2,
          style: { textAlign: "center", marginRight: 15, marginTop: 10 },
        });
        // fetchItems();
        handleCancel("saved");
        onReset();
      } else {
        message.open({
          type: "error",
          content: "Error Creating ItemCode",
          duration: 2,
          style: { textAlign: "center", marginRight: 15, marginTop: 10 },
        });
      }
    } catch (e) {
      console.log("error exception ** ", e);
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={() => handleCancel()}
      footer={null}
      title={`${selectedItem ? "Edit Item" : "Create ItemID"}`}
      centered
      width="70%"
      destroyOnClose
    >
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          preserve={false}
          initialValues={selectedItem}
        >
          <Space
            direction="vertical"
            // size={60}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Form.Item label="Category" name="category">
              <Select
                placeholder="Select category"
                showSearch
                // style={{ width: 180 }}
                allowClear
              >
                {renderEnumOptions(Category)}
              </Select>
            </Form.Item>
            <Form.Item label="Type" name="type">
              <Select
                placeholder="Select type"
                showSearch
                // style={{ width: 180 }}
                allowClear
              >
                {renderEnumOptions(ItemTypesEnum)}
              </Select>
            </Form.Item>
            <Form.Item label="Name" name="name">
              <Input placeholder="Enter name" />
            </Form.Item>
            <Form.Item label="Value" name="value">
              <Input placeholder="Enter value" />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                return (
                  form.getFieldValue("type") === "other" && (
                    <Form.Item label="Price" name="price">
                      <InputNumber placeholder="Enter price" />
                    </Form.Item>
                  )
                );
              }}
            </Form.Item>
          </Space>
          <Form.Item style={{ marginTop: 20 }}>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Create ItemID
                </Button>
                <Button
                  htmlType="button"
                  onClick={onReset}
                  style={{ backgroundColor: "green", color: "white" }}
                >
                  Reset
                </Button>
              </Space>
            </Col>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
