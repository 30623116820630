import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, Input, Space, Popconfirm, message, Row } from "antd";
import {
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { FilterConfirmProps } from "antd/es/table/interface";
import MainLayout from "../../components/MainLayout";
import UpdateCustomer from "./UpdateCustomer";
import { useBusiness } from "../../services/BuisnessService";
import { useConnection } from "../../services/ApplicationContext";
import { ProfessionalCustomer } from "../../services/BusinessModel";
import { AuthFeatures } from "../../Constant/AuthorizationList";
import { useUser } from "../../services/UserContext";

interface DataType {
  key: string;
  shopName: string;
  owner: string;
  contactNumber: string;
  shop: any;
  address: string;
  joinDate?: Date;
  accountBalance: number;
  note?: string;
  _id?: string;
  status: any;
}

type DataIndex = keyof DataType;
const Professional: FC = () => {
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { customers, fetchCustomers } = useBusiness();
  const [editingKey, setEditingKey] = useState("");
  const { delete: del } = useConnection();
  const history = useHistory();
  const { isAuthorized } = useUser();

  const edit = (record: any) => {
    console.log("on select customer data ", record);
    setSelectedRow({ ...record, joinDate: moment(record.joinDate).local() });
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  const Delete = async (record: ProfessionalCustomer) => {
    setLoading(true);
    try {
      const result = await del(`customer/${record._id}`);
      if (result.status === 200) {
        await fetchCustomers();
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        // content: "Error occurred",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<any>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: DataIndex): any => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text: any) => {
      return text;
    },
  });
  const columns: any = [
    {
      title: "Studio Name",
      dataIndex: "shopName",
      key: "studioName",
      filterSearch: true,
      ...getColumnSearchProps("shopName"),
      // render: (_: any, record: ProfessionalCustomer) => (
      //   <span>{record.shopName}</span>
      // ),
    },
    { title: "Owner Name", dataIndex: "owner", key: "ownerName" },
    {
      title: "Phone",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    { title: "Address", dataIndex: "address", key: "address" },
    {
      title: "Account Balance",
      dataIndex: "accountBalance",
      key: "accountBalance",
    },
    {
      title: "Join Date",
      dataIndex: "joinDate",
      key: "joinDate",
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a aria-disabled={editingKey !== ""} onClick={() => edit(record)}>
              <EditFilled />
            </a>
            <a onClick={() => history.push(`/profile/${record._id}`)}>
              <EyeOutlined />
            </a>
            {isAuthorized(AuthFeatures.DELETE_PROFESSIONAL_CUSTOMER) && (
              <Popconfirm
                title="Are you sure to delete this order?"
                onConfirm={() => Delete(record)}
                okText="Yes"
                okButtonProps={{ danger: true }}
                cancelText="No"
                cancelButtonProps={{ type: "primary" }}
              >
                <a>
                  <DeleteFilled />
                </a>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];
  const onClose = async (status?: "success" | "failed") => {
    if (status && status === "success") {
      await fetchCustomers();
    }
    setSelectedRow(undefined);
    setAddModalVisible(false);
  };
  return (
    <MainLayout type="primary" title="Professional Customer">
      {/* model should be added here */}
      <UpdateCustomer
        onClose={onClose}
        visible={addModalVisible}
        edit={false}
      />
      {selectedRow !== undefined && (
        <UpdateCustomer onClose={onClose} visible edit data={selectedRow} />
      )}
      <Button
        type="primary"
        className="add-device-btn"
        onClick={() => setAddModalVisible(true)}
      >
        <PlusOutlined style={{ fontSize: "17px", fontWeight: "bold" }} />
        Add Professional Customer
      </Button>
      <Table dataSource={customers} columns={columns} />
    </MainLayout>
  );
};
export default Professional;
