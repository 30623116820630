import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, Select, Space } from "antd";
import { useBusiness } from "../../services/BuisnessService";
import { FilterProps } from "../ProfessionalOrders/FilterProfessional";

export const FilterAmateur: FC<FilterProps> = ({ onFinishSearch, onReset }) => {
  const [form] = Form.useForm();
  const { customers } = useBusiness();
  const onResetForm = () => {
    form.resetFields();
    onReset();
  };
  return (
    <Form layout="inline" form={form} onFinish={onFinishSearch}>
      <Form.Item name="_id">
        <Input placeholder="Enter Order ID" />
      </Form.Item>
       <Form.Item name="customerName">
        <Input placeholder="Enter customer Name" />
       </Form.Item>
      <Form.Item name="contactNumber">
        <Input placeholder="Enter Phone Number" />
      </Form.Item>
      {/* <Form.Item> */}
      {/*  <Input placeholder="Enter Date" /> */}
      {/* </Form.Item> */}
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button onClick={onResetForm}>Reset</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
