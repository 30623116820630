import React, { useState, useEffect, FC } from "react";
import {
  Table,
  Popconfirm,
  Space,
  Button,
  message,
  Skeleton,
  Row,
} from "antd";
import {
  EditFilled,
  DeleteFilled,
  PlusOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import "./Styles.css";
import { TablePaginationConfig } from "antd/es/table/Table";
import moment from "moment";
import UpdateOrder from "./UpdateOrder";
import { useConnection } from "../../services/ApplicationContext";
import {
  IProfessionalOrderTableEntry,
  IProfessionalOrderUpdateDTO,
  ProfessionalOrderItem,
} from "../../services/BusinessModel";
import PaymentHandler from "./PaymentHandler";
import { checkKey, fillSubTotals } from "../../utilities/Utilities";
import { FilterProfessional } from "./FilterProfessional";

export interface OrderTableProps {
  customerId?: string;
}
const OrderTable: FC<OrderTableProps> = (props: OrderTableProps) => {
  const { customerId } = props;
  const glbPageSize = 10;
  const { delete: del, post } = useConnection();

  const [selectedRow, setSelectedRow] = useState<IProfessionalOrderUpdateDTO>();
  const [
    selectedPayRow,
    setSelectedPayRow,
  ] = useState<IProfessionalOrderTableEntry>();
  const [data, setData] = useState<IProfessionalOrderTableEntry[]>();
  const [total, setTotal] = useState<number>(0);
  const [searchValues, setSearchValues] = useState<any>();

  const [loading, setLoading] = useState(true);
  const [editingKey, setEditingKey] = useState("");
  const fetchOrders = async (index?: number, reset?: boolean) => {
    setLoading(true);
    try {
      let filters = {};
      if (customerId) {
        filters = { customer: customerId };
      }

      if (searchValues && !reset) {
        filters = { ...filters, ...searchValues };
      }
      const result = await post("orders/filter-professional", {
        filters,
        skip: index ?? 0,
        limit: glbPageSize,
      });
      if (result && result.data && result.status === 200) {
        if (result.data.data && result.data.data.length > 0) {
          setData(result.data.data);
          if (result.data.meta) {
            setTotal(result.data.meta.total);
          }
        } else {
          setTotal(0);
          setData([]);
          setSearchValues(undefined);
        }
      }
      setLoading(false);
    } catch (e: any) {
      message.error(e.message);
      setLoading(false);
      setData([]);
    }
  };
  useEffect(() => {
    fetchOrders();
  }, [customerId]);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);

  const edit = (record: IProfessionalOrderTableEntry) => {
    let { orderList } = record;
    orderList = orderList.map((vl: ProfessionalOrderItem) => ({
      ...vl,
      showLaminate: vl.laminateType !== undefined,
      showFrame: vl.frameType !== undefined,
    }));
    console.log("selected prof order ", record);
    const { _id } = record?.shop;
    let payment = 0;
    if (record.payment && record.payment.length > 0) {
      payment = record.payment
        .map((vl) => vl.amount)
        .reduce((prev, curr) => prev + curr);
    }
    setSelectedRow({
      ...record,
      orderList,
      customer: _id,
      payment,
      originalPaymentDetails: record.payment,
    });
  };

  const handleOrder = (record: IProfessionalOrderTableEntry) => {
    const newRecord = fillSubTotals(record);
    if (newRecord) {
      setSelectedPayRow(newRecord as IProfessionalOrderTableEntry);
    }
  };
  const Delete = async (record: IProfessionalOrderTableEntry) => {
    setLoading(true);
    try {
      const result = await del(`orders/professional/${record._id}`);
      if (result.status === 200) {
        await fetchOrders();
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        // content: "Error occurred",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };
  const columns: any = [
    {
      title: "Order Id",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Shop Name",
      dataIndex: "customer",
      key: "customerName",
      render: (_: any, val: any) => {
        return <span>{val?.shop?.shopName}</span>;
      },
    },
    {
      title: "Phone",
      dataIndex: "customer",
      key: "contactNumber",
      render: (_: any, val: any) => <span>{val?.shop?.contactNumber}</span>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (_: any, val: any) => (
        <span>
          {moment.utc(val.orderDate).local().format("YYYY MMM DD  -  HH:mm")}
        </span>
      ),
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a aria-disabled={editingKey !== ""} onClick={() => edit(record)}>
              <EditFilled />
            </a>
            <a onClick={() => handleOrder(record)}>
              <CreditCardOutlined />
            </a>
            <Popconfirm
              title="Are you sure to delete this order?"
              onConfirm={() => Delete(record)}
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              cancelButtonProps={{ type: "primary" }}
            >
              {" "}
              <a>
                <DeleteFilled />
              </a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const onModalClose = async (
    add: "add" | "edit",
    status?: "success" | "failed"
  ) => {
    try {
      if (status) {
        await fetchOrders();
      }
    } catch (e: any) {
      console.log("error after update order ", e);
    }

    setSelectedRow(undefined);
    setAddModalVisible(false);
  };
  const onClosePaymentModel = async (val?: any) => {
    await fetchOrders();
    setSelectedPayRow(undefined);
  };
  const onReset = () => {
    setSearchValues(undefined);
    fetchOrders(0, true);
  };
  const onFinishSearch = (values: any) => {
    if (checkKey(values, "_id")) {
      values._id = values._id.toUpperCase();
    }
    setSearchValues(values);
  };
  useEffect(() => {
    console.log("search values ", searchValues);
    if (searchValues) {
      fetchOrders(0);
    }
  }, [searchValues]);
  return (
    <>
      {selectedRow !== undefined && (
        <UpdateOrder data={selectedRow} visible onClose={onModalClose} edit />
      )}
      {addModalVisible && (
        <UpdateOrder
          visible={addModalVisible}
          onClose={onModalClose}
          edit={false}
        />
      )}
      {selectedPayRow && (
        <PaymentHandler
          data={selectedPayRow}
          visible={selectedPayRow !== undefined}
          onClose={onClosePaymentModel}
        />
      )}
      <>
        {!customerId && (
          <Row>
            <Space size="large">
              <Button
                type="primary"
                className="add-device-btn"
                onClick={() => setAddModalVisible(true)}
              >
                <PlusOutlined
                  style={{ fontSize: "17px", fontWeight: "bold" }}
                />
                Add Order
              </Button>
            </Space>
          </Row>
        )}
        <Row style={{ margin: "20px 10px 20px 10px" }}>
          <FilterProfessional
            onFinishSearch={onFinishSearch}
            onReset={onReset}
          />
        </Row>
      </>
      {data ? (
        <>
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{ pageSize: glbPageSize, total }}
            onChange={(val: TablePaginationConfig) => {
              const temp = val.current ? val.current - 1 : 0;
              fetchOrders(temp * glbPageSize);
            }}
            scroll={{ x: 1300 }}
          />
        </>
      ) : (
        <Skeleton active paragraph={{ rows: 10 }} round />
      )}
    </>
  );
};

export default OrderTable;
