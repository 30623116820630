import React, { FC, useState, useEffect } from "react";
import { Button, message, Popconfirm, Space, Table } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useConnection } from "../../services/ApplicationContext";
import { IItem } from "../../Constant/ItemModel";
import { MangeItemModal } from "./ManageItemModal";

const Items: FC = () => {
  const { get, delete: del } = useConnection();

  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState<IItem>();

  const fetchItems = async () => {
    setLoading(true);
    try {
      const result = await get(`items`);

      if (result.status === 200) {
        console.log("Items ", result);
        setData(result.data);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchItems();
  }, []);
  const handleCancel = (val?: "saved") => {
    if (val && val === "saved") {
      fetchItems();
    }
    setVisible(false);
    setSelectedItem(undefined);
  };
  const onEdit = (record: any) => {
    setSelectedItem(record);
    setVisible(true);
  };
  const Delete = async (record: any) => {
    setLoading(true);
    try {
      const result = await del(`items/${record._id}`);
      if (result.status === 200) {
        await fetchItems();
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      message.open({
        type: "error",
        content: e.message,
        // content: "Error occurred",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    // {
    //   title: "price",
    //   dataIndex: "price",
    //   key: "price",
    // },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a onClick={() => onEdit(record)}>
              <EditFilled />
            </a>
            <Popconfirm
              onConfirm={() => Delete(record)}
              title="Are you sure to delete this Item?"
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              cancelButtonProps={{ type: "primary" }}
            >
              <a>
                <DeleteFilled />
              </a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Button onClick={() => setVisible(true)} type="primary">
          Create Item
        </Button>
      </div>
      {visible === true && (
        <MangeItemModal
          handleCancel={handleCancel}
          selectedItem={undefined}
          visible={visible}
        />
      )}
      {selectedItem && (
        <MangeItemModal
          handleCancel={handleCancel}
          selectedItem={selectedItem}
          visible={selectedItem !== undefined}
        />
      )}
      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={data}
          // bordered
          loading={loading}
          style={{ width: "90%" }}
        />
      </div>
    </>
  );
};
export default Items;
