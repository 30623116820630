import React, { FC } from "react";

const UnAuthorized: FC = () => {
  return (
    <div className="App-header">
      <h1 className="warning-text">
        You are not authorized to access this page!
      </h1>
      <div className="txt404">404</div>
    </div>
  );
};
export default UnAuthorized;
