/* eslint-disable prettier/prettier */
import React, {FC, useCallback, useEffect, useState} from "react";
import {Collapse} from "antd";


import MainLayout from "../../../components/MainLayout";
import {useConnection} from "../../../services/ApplicationContext";
import {SalesSummary} from "./SalesComponents";
import {Category} from "../../../Constant/ItemModel";

const Sales: FC = () => {
    const [professionalSales, setProfessionalSales] = useState<any>()
    const [amateurSales, setAmateurSales] = useState<any>()
    const [professionalMonthlySales, setProfessionalMonthlySales] = useState<any>()
    const [amateurMonthlySales, setAmateurMonthlySales] = useState<any>()
    const [dateRange, setDateRange] = useState<any>({ dateFrom: '2022-01-30', dateTo: '2022-12-30' })

    const { Panel } = Collapse;
    const { post, get } = useConnection();

    const getSalesStats = useCallback(
        async (type: Category) => {
            try {
                const daily = await post(`orders/${type}/statistics`);
                if(type === Category.AMATEUR){
                    setAmateurSales(daily.data.data)
                }
                if(type === Category.PROFESSIONAL){
                    setProfessionalSales(daily.data.data)
                }

                // const monthly = await post('orders/professional/statistics', dateRange);
                // setProfessionalSales(monthly.data.data)


            } catch (e) {
                console.log('error sales', e)
            }
        }, [ post])
    const getMonthlySalesStats = useCallback(
        async (type: Category) => {
            try {
                const daily = await post(`orders/${type}/statistics`,dateRange);
                if(type === Category.AMATEUR){
                    setAmateurMonthlySales(daily.data.data)
                }
                if(type === Category.PROFESSIONAL){
                    setProfessionalMonthlySales(daily.data.data)
                }

                // const monthly = await post('orders/professional/statistics', dateRange);
                // setProfessionalSales(monthly.data.data)


            } catch (e) {
                console.log('error sales', e)
            }
        }, [dateRange, post])
    useEffect(() => {
        getSalesStats(Category.AMATEUR);
        getSalesStats(Category.PROFESSIONAL);
    }, [getSalesStats])
    useEffect(() => {
        getMonthlySalesStats(Category.AMATEUR);
        getMonthlySalesStats(Category.PROFESSIONAL);
    }, [getMonthlySalesStats])
    return (
        <MainLayout type="primary" title="Sales">
            <Collapse bordered={false}>
                   <SalesSummary type={Category.AMATEUR} />
                   <SalesSummary type={Category.PROFESSIONAL} />
            </Collapse>
        </MainLayout>
    );
};

export default Sales;
