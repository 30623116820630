import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { useUser } from "./services/UserContext";

export interface IPrivateRoute extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  feature?: string;
}
const PrivateRoute: FC<IPrivateRoute> = (props: IPrivateRoute) => {
  const { component: Component, ...rest } = props;
  const { isAuthenticated, isAuthorized } = useUser();

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (isAuthenticated()) {
          if (isAuthorized()) {
            return <Component {...routeProps} />;
          }
          return (
            <Redirect
              to={{
                pathname: "/unauthorized",
                state: { from: routeProps.location },
              }}
            />
          );
        }
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
