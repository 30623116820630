export enum ItemTypesEnum {
  FRAME = "frame",
  LAMINATE = "laminate",
  PHOTO = "photo",
  OTHER = "other",
}
export enum Category {
  PROFESSIONAL = "professional",
  AMATEUR = "amateur",
}
export interface IItemBase {
  readonly name: string;
  readonly value: string;
  // readonly price: number;
  _id?: string;
}
export interface IItem extends IItemBase{
  readonly category: Category;
  readonly type: ItemTypesEnum;
  readonly price?: number;
}
