import React, {
  FC,
  ReactNode,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import moment from "moment";
import { DollarOutlined, FieldTimeOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import {
  IProfessionalOrderTableEntry,
  ProfessionalCustomer,
} from "../../services/BusinessModel";
import { useConnection } from "../../services/ApplicationContext";
import "./Styles.css";
import { fillSubTotals } from "../../utilities/Utilities";

export interface PaymentHandlerProps {
  data: IProfessionalOrderTableEntry;
  visible: boolean;
  onClose: (val?: IProfessionalOrderTableEntry) => void;
  // customer: ProfessionalCustomer;
}
const orderStatusList = [
  { label: "None", value: "none" },
  { label: "Completed", value: "completed" },
  { label: "In-Progress", value: "in-progress" },
];
const PaymentHandler: FC<PaymentHandlerProps> = (
  props: PaymentHandlerProps
) => {
  const { visible, onClose, data: order } = props;
  const { get, patch } = useConnection();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [payment, setPayment] = useState<number>(0);
  const [discountState, setDiscountState] = useState<number>();
  const [payFromAccount, setPayFromAccount] = useState<boolean>(false);
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const [shouldSaveToAccount, setShouldSaveToAccount] = useState<boolean>(
    false
  );
  const [change, setChange] = useState<number>(0);
  const [dueNode, setDueNode] = useState<ReactNode>();
  const [dueNodeBill, setDueNodeBill] = useState<ReactNode>();
  const [
    orderAfterUpdate,
    setOrderAfterUpdate,
  ] = useState<IProfessionalOrderTableEntry>();
  const data: IProfessionalOrderTableEntry = useMemo(() => {
    if (orderAfterUpdate) {
      const temp = form.getFieldsValue();
      console.log(" order in memo 0", order);
      console.log(" order in memo 1", orderAfterUpdate);
      console.log(" order in memo 2", {
        ...order,
        ...orderAfterUpdate,
        ...temp,
        customer: order.customer,
      });
      return {
        ...order,
        ...orderAfterUpdate,
        ...temp,
        customer: order.customer,
      };
    }
    return order;
  }, [order, orderAfterUpdate]);
  const discount = useMemo(() => {
    if (discountState) {
      return discountState;
    }
    if (data.discount) {
      return data.discount;
    }
    return 0;
  }, [data, discountState]);
  const getCurrentPaidAmount = () => {
    let paymentX = 0;
    if (data.payment && data.payment.length > 0) {
      paymentX = data.payment
        .map((vl) => vl.amount)
        .reduce((prev, curr) => prev + curr);
    }
    return paymentX;
  };
  const fetchOrder = async (): Promise<any> => {
    try {
      return await get(`orders/professional/${data._id}`);
    } catch (e: any) {
      return null;
      console.log("error fetching a single order ", e);
    }
  };
  const getPossibleAmountToSettle = (bill: number) => {
    return Math.abs(data.shop?.accountBalance) > bill
      ? bill
      : Math.abs(data.shop?.accountBalance);
  };
  const payFromACBalance = (val: any, bill: number) => {
    setPayFromAccount(val.target.checked);
    if (val.target.checked) {
      form.setFieldsValue({ newPayment: getPossibleAmountToSettle(bill) });
    }
  };
  const calculateDue = () => {
    let paymentx = 0;

    if (data.payment && data.payment.length > 0) {
      paymentx = getCurrentPaidAmount();
    }
    let bill: number;
    console.log("discount in calc due ", discount);
    if (discount) {
      bill = data.total - paymentx - discount;
    } else {
      bill = data.total - paymentx;
    }
    if (bill <= 0) {
      setDueNode(
        <div>
          <span className="data-label">Order Payments Completed! </span>
        </div>
      );
      setDueNodeBill(
        <div>
          <span className="data-labels">Order Payments Completed! </span>
        </div>
      );
    } else {
      setDueNode(
        <div>
          <span className="data-label">Payable Amount : </span>
          <span className="data-label">{bill} LKR</span>
          {data.shop?.accountBalance < 0 && (
            <Col>
              <span>
                To settle {getPossibleAmountToSettle(bill)} LKR, please click
                checkbox.
              </span>
              <Checkbox
                style={{ marginLeft: 24 }}
                onChange={(val) => payFromACBalance(val, bill)}
              >
                Pay
              </Checkbox>
            </Col>
          )}
        </div>
      );
      setDueNodeBill(
        <Row gutter={[8, 24]}>
          <Col span={8}>
            <span className="data-labels">Payable Amount </span>
          </Col>
          <Col span={8}>{}</Col>
          <Col span={8}>
            <span className="data-labels">{bill} LKR</span>
          </Col>
        </Row>
      );
    }
  };
  const calculateTotalCurrentPayment = () => {
    setPayment(getCurrentPaidAmount());
  };

  const onFinish = async (values: any) => {
    const entry = { ...values };
    if (data.payment.length > 0) {
      entry.payment = [
        ...data.payment,
        {
          amount: values.newPayment,
          date: Number(Date.now()),
          fromAccount: payFromAccount,
        },
      ];
      if (isNaN(values.newPayment)) {
        delete entry.payment;
      }
    } else {
      entry.payment = [
        {
          amount: values.newPayment,
          date: Number(Date.now()),
          fromAccount: payFromAccount,
        },
      ];
      if (isNaN(values.newPayment)) {
        delete entry.payment;
      }
    }
    try {
      const result = await patch(
        `orders/professionalOrderPayment/${data._id}`,
        { ...data, ...entry, payFromAccount }
      );
      console.log("pay result ", result);
      if (result.status === 200) {
        const remp = await fetchOrder();
        if (remp.status === 200) {
          const newRecord = fillSubTotals(remp.data);
          setOrderAfterUpdate(newRecord as IProfessionalOrderTableEntry);
        }

        message.open({
          type: "success",
          content: "Order Updated!",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        // onClose();
      }
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        // content: "Error occurred",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  const checkKey = (obj: any, key: string): boolean => {
    return Object.hasOwnProperty.call(obj, key) && obj[key] !== undefined;
  };
  const onFormChange = (diff: any, value: any) => {
    if (checkKey(value, "newPayment") && checkKey(value, "cash")) {
      if (
        checkKey(diff, "newPayment") ||
        checkKey(diff, "cash") ||
        checkKey(diff, "payingAmountToAccount")
      ) {
        const val = Number(value.cash) - Number(value.newPayment);

        if (shouldSaveToAccount && checkKey(diff, "payingAmountToAccount")) {
          console.log(" sss ", val - Number(value.payingAmountToAccount));
          setChange(val - Number(value.payingAmountToAccount));
        } else {
          setChange(val);
          form.setFieldsValue({ payingAmountToAccount: val });
        }
      }
    }
  };
  // const subTotal = () => {};

  useEffect(() => {
    calculateTotalCurrentPayment();
    calculateDue();
    // subTotal();
  }, [data, discount]);

  return (
    <Modal
      title="Order Details"
      centered
      visible={visible}
      onCancel={() => onClose()}
      width="90%"
      footer={null}
    >
      <div className="data-main-container">
        <div className="data-container">
          <Row justify="space-between">
            <Col span={12}>
              <span className="data-label">Shop Name: </span>{" "}
              <span className="data-text">{data.shop.shopName}</span>
            </Col>
            <Col span={12}>
              <span className="data-label">Order Date: </span>{" "}
              <span className="data-text">
                {moment
                  .utc(data.orderDate)
                  .local()
                  .format("YYYY MMM DD  -  HH:mm")}
              </span>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={12}>
              <span className="data-label">Total: </span>{" "}
              <span className="data-text">{data.total} LKR</span>
            </Col>
            <Col span={12}>
              <span className="data-label">Notes: </span>{" "}
              <span className="data-text">{data.note ?? "none"}</span>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={12}>
              <span className="data-label">Payment Status: </span>{" "}
              <span className="data-text">{data.paymentStatus}</span>
            </Col>
            <Col span={12}>
              <span className="data-label">Order Status: </span>{" "}
              <span className="data-text">{data.orderStatus}</span>
            </Col>
          </Row>
          {discount !== undefined && Number(discount) > 0 && (
            <Row justify="space-between">
              <Col span={12}>
                <span className="data-label">Discount: </span>{" "}
                <span className="data-text">{discount} LKR</span>
              </Col>
            </Row>
          )}
          <Divider />
          <span className="list-heading-pay">Payment History</span>
          <>
            {data.payment.map((val, index) => (
              <Row key={index.toString()} justify="start">
                <Col span={6}>
                  <span className="data-text-2">
                    <DollarOutlined
                      style={{ marginRight: 10, color: "#ea6950" }}
                    />
                    {val.amount} LKR
                  </span>
                </Col>
                <Col>
                  <span className="data-text-2">
                    <FieldTimeOutlined
                      style={{
                        marginLeft: 60,
                        marginRight: 10,
                        color: "#ea6950",
                      }}
                    />
                  </span>
                </Col>{" "}
                {moment.utc(val.date).local().format("YYYY MMM DD  -  HH:mm")}
              </Row>
            ))}
          </>
          <br />
          <Divider />
          <span className="list-heading-pay">Handle Order</span>

          <Form
            {...formItemLayout}
            name="prof-order"
            form={form}
            initialValues={data}
            onFinish={onFinish}
            scrollToFirstError
            autoComplete="off"
            style={{ justifyContent: "center" }}
            className="ant-advanced-search-form"
            layout="vertical"
            onValuesChange={onFormChange}
          >
            {data.shop?.accountBalance < 0 && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={12}>
                  Customer account balance:{" "}
                  {Math.abs(data.shop?.accountBalance)} LKR.
                </Col>
              </Row>
            )}
            <Row key="total-payable" justify="start">
              {dueNode}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="newPayment"
                  label="Payment"
                  className="order-item"
                  labelCol={{ span: 24 }}
                >
                  <InputNumber disabled={payFromAccount} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Order Status"
                  name="orderStatus"
                  className="display"
                  rules={[
                    {
                      required: true,
                      message: "Please enter customer's Name!",
                    },
                  ]}
                >
                  <Select placeholder="Select Order Status">
                    {orderStatusList.map((vl, index) => (
                      <Select.Option key={index.toString()} value={vl.value}>
                        {vl.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label="Discount" name="discount" className="display">
                  <InputNumber
                    value={discount}
                    onChange={(val: any) => setDiscountState(val)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="cash"
                  label="Cash"
                  className="order-item"
                  labelCol={{ span: 24 }}
                  id="cash"
                >
                  <InputNumber  disabled={payFromAccount} />
                </Form.Item>
              </Col>
              {/* <Col className="gutter-row" span={12}>
                <Checkbox
                  value={shouldSaveToAccount}
                  onChange={(val) => setShouldSaveToAccount(val.target.checked)}
                >
                  Should Save To Account{" "}
                </Checkbox>
                {shouldSaveToAccount && (
                  <Form.Item
                    name="payingAmountToAccount"
                    label="Paying Amount"
                    className="order-item"
                    labelCol={{ span: 24 }}
                  >
                    <InputNumber />
                  </Form.Item>
                )}
              </Col> */}
            </Row>
            <Row>
              <Col>
                <span className="data-label">Change </span> <br />
                <span className="data-text">{change}</span>
              </Col>
            </Row>

            <Form.Item className="modal-button ant-form-item-control-input-content">
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                style={{ margin: "15px" }}
              >
                Update
              </Button>
              <Button
                loading={loading}
                danger
                type="primary"
                style={{ margin: "15px" }}
                onClick={() => onClose(data)}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Divider type="vertical" className="divider-bill" />
        <div className="bill-container">
          <div ref={componentRef} className="bill-container-recipt">
            <div className="bill-head">
              <h3
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                GUNARATHNA COLOUR LAB
              </h3>
              <span className="data-labels" style={{ textAlign: "center" }}>
                No 20, Weerasundararama Mawatha,
                <br />
                <span className="data-labels" style={{ textAlign: "center" }}>
                  Balangoda
                </span>
                <br />
              </span>
              <span className="data-labels" style={{ textAlign: "center" }}>
                Tel : 0452288130 <br />
                Email : gunarathnalab@gmail.com
              </span>{" "}
            </div>
            <br />
            <h4 style={{ color: "black", fontWeight: "bold" }}>
              Order List Details{" "}
            </h4>
            <div>
              <span className="data-labels">Order Id : </span>
              <span className="data-details">{data._id}</span>
            </div>
            <div>
              <span className="data-labels">Shop Name : </span>
              <span className="data-details">{data.shop.shopName}</span>
            </div>
            <div>
              <span className="data-labels">Order Date : </span>
              <span className="data-details">
                {moment.utc(data.orderDate).local().format("YYYY MMM DD")}
              </span>
            </div>
            <Divider
              type="horizontal"
              dashed
              style={{
                backgroundColor: "#000",
                opacity: 0.5,
                marginTop: 5,
                marginBottom: 5,
              }}
            />
            <div>
              <div>
                <Row gutter={[8, 24]}>
                  <Col span={8}>
                    <span className="data-labels">Details</span>{" "}
                  </Col>
                  <Col span={8}>
                    <span className="data-labels">Quantity</span>{" "}
                  </Col>
                  <Col span={8}>
                    <span className="data-labels">Price (LKR)</span>
                  </Col>
                </Row>
                {/* <span className="data-labels">Count :</span>
                    <span className="data-details">{val.count}</span> */}
              </div>
              {data.orderList.map((val) => (
                <div>
                  <Row gutter={[8, 24]}>
                    <Col span={8}>
                      {val.itemCode ? (
                        <span className="data-labels">{val.itemCode}</span>
                      ) : (
                        <span className="data-labels">
                          {!val.size && !val.frameType && !val.laminateType
                            ? "Other"
                            : (val.size ?? "") +
                              (val?.frameType ?? "") +
                              (val?.laminateType ?? "")}
                        </span>
                      )}
                    </Col>
                    <Col span={8}>
                      <span className="data-details">{val.count}</span>
                    </Col>
                    <Col span={8}>
                      <span className="data-details">
                        {val.subTotal || val?.subtotal}
                      </span>
                    </Col>
                  </Row>
                </div>
              ))}

              <Divider
                type="horizontal"
                dashed
                style={{
                  backgroundColor: "#000",
                  opacity: 0.5,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              />
            </div>
            <div>
              <Row gutter={[8, 24]}>
                <Col span={8}>
                  <span className="data-labels" style={{ fontWeight: 500 }}>
                    Total :{" "}
                  </span>
                </Col>
                <Col span={8} />
                <Col span={8}>
                  <span className="data-details" style={{ fontWeight: 500 }}>
                    {data.total} LKR
                  </span>
                </Col>
              </Row>
              <Divider
                type="horizontal"
                dashed
                style={{
                  backgroundColor: "#000",
                  opacity: 0.5,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              />
            </div>
            {form.getFieldValue("cash") && (
              <div>
                <h4 style={{ color: "black", fontWeight: "bold" }}>Receipt</h4>
                <span className="data-labels">Date : </span>

                <span className="data-details">
                  {moment().format("DD-MM-YYYY")}
                </span>
                <br />
                <span className="data-labels">Cash : </span>
                <span className="data-labels">
                  {form.getFieldValue("cash")} LKR
                </span>
                <br />
                <span className="data-labels">Payment : </span>
                <span className="data-labels">
                  {form.getFieldValue("newPayment")} LKR
                </span>
                <br />
                <span className="data-labels">Balance : </span>
                <span className="data-labels">{change} LKR</span>
                <br />
              </div>
            )}
          </div>
          <div className="printBtn">
            <ReactToPrint
              trigger={() => (
                <Button type="primary" color="primary">
                  Print Receipt
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PaymentHandler;
