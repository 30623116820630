import React, { FC, useState, useEffect } from "react";
import { Button, Collapse } from "antd";
import MainLayout from "../../components/MainLayout";
import "./Styles.css";
import moment from "moment";
import { useParams } from "react-router";
import { useConnection } from "../../services/ApplicationContext";
import OrderTable from "../ProfessionalOrders/OrderTable";
import UnpaidOrderTable from "./UnpaidOrdersTable";

const CustomerProfile: FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(Object);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const { id } = useParams<{ id?: string }>();
  const { get } = useConnection();

  const { Panel } = Collapse;

  const fetchCustomer: any = async () => {
    setLoading(true);
    try {
      const result = await get(`customer/${id}`);
      setData(result.data);
      if (result.status === 200) {
        console.log("Customer ", result);
      }

      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchCustomer(id);
    }
  }, [id]);

  const onModalClose = async (status?: "success" | "failed") => {
    try {
      if (status) {
        await fetchCustomer();
      }
    } catch (e: any) {
      console.log("error after update order ", e);
    }
    setAddModalVisible(false);
  };
  return (
    <>
      <MainLayout type="primary" title="Customer Profile">
        <div className="main-container-profile">
          <div className="profile-view">
            <h3> Profile View</h3>
            <div className="profile-view-subContainer">
              <div className="profile-view-lables">
                <label className="label1">Owner</label>
                <label>{data.owner}</label>
              </div>

              <div className="profile-view-lables">
                <label className="label1">Shop Name</label>
                <label>{data.shopName}</label>
              </div>

              <div className="profile-view-lables">
                <label className="label1">Mobile</label>
                <label>{data.contactNumber}</label>
              </div>

              <div className="profile-view-lables">
                <label className="label1">Address</label>
                <label>{data.address}</label>
              </div>

              <div className="profile-view-lables">
                <label className="label1">Join Date</label>
                <label>
                  {moment(data.joinDate).local().format("YYYY-MM-DD")}
                </label>
              </div>
              <div className="profile-view-lables">
                <label className="label1">Account Balance</label>
                <label>{data.accountBalance} LKR</label>
              </div>
            </div>
          </div>
          <div className="stats">
            {/*
            <div className="stat-lables">
              <Space direction="vertical">
                <Space size="large">
                  <label className="label1">Account Balance :</label>
                  <label>{data.accountBalance}</label>{" "}
                </Space>
                <Space size="large">
                  <label className="label1">Last Payment Date :</label>
                  <label>{data.accountBalance}</label> <br />
                </Space>
              </Space>
            </div> */}
          </div>
        </div>
        <div>
          {addModalVisible && (
            <UnpaidOrderTable
              visible={addModalVisible}
              onClose={onModalClose}
            />
          )}
          <Button
            type="primary"
            className="add-device-btn"
            onClick={() => setAddModalVisible(true)}
          >
            Manage Unpaid Orders
          </Button>
        </div>
        <div className="payment-history">
          <Collapse bordered={false}>
            <Panel header="Order History" key="1">
              <div className="payment-history-table">
                {id && <OrderTable customerId={id} />}
              </div>
            </Panel>
          </Collapse>
        </div>
      </MainLayout>
    </>
  );
};
export default CustomerProfile;
