import { Dropdown, Layout, Menu, Avatar } from "antd";
import React, { FC } from "react";
import {
  CaretDownFilled,
} from "@ant-design/icons";
import "./Styles.css";
import { useConnection } from "../../services/ApplicationContext";
import { useUser } from "../../services/UserContext";

const HeaderX: FC = () => {
  const { Header } = Layout;
  const { updateToken } = useConnection();
  const { logout, userInfo } = useUser();

  const signOut = (): void => {
    updateToken();
    logout();
  };
  const userSettingsMenu = (
    <Menu mode="inline" defaultSelectedKeys={["51"]} defaultOpenKeys={["sub1"]}>
      {/*<Menu.Item key="51">*/}
      {/*  <NavLink to="/update-user">Edit Profile</NavLink>*/}
      {/*</Menu.Item>*/}
      <Menu.Item key="52" onClick={signOut}>
        Sign Out
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      className="header"
      style={{ paddingLeft: "1%", paddingRight: "1%" }}
    >
      <div className="header-container">
        <h3 className="header-xep">Gunarathna studio</h3>
        {/* <Search
                        placeholder="Search.."
                        onSearch={value => console.log(value)}
                        className='header-search'
                        prefix={<SearchOutlined className="site-form-item-icon" style={{color:'#898E9E',fontSize:'15px'}}/>}
                    /> */}
        <div className="icon-container">
          {/*<div className="bell-icon-container">*/}
          {/*  <BellFilled className="bell-icon" />*/}
          {/*</div>*/}
          <div className="user-container">
            <Menu
              theme="dark"
              mode="horizontal"
              style={{
                float: "right",
                backgroundColor: "transparent",
                fontWeight: "bold",
              }}
            >
              <Menu.Item key="1">
                <Dropdown
                  overlay={userSettingsMenu}
                  trigger={["click"]}
                  placement="bottomCenter"
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Avatar shape="circle" src={require("./userIcon.png")} />{" "}
                    {userInfo?.firstName}
                    <CaretDownFilled
                      style={{ marginLeft: "5px", marginRight: "-2px" }}
                    />
                  </a>
                </Dropdown>
              </Menu.Item>
            </Menu>
          </div>
          {/*<div>*/}
          {/*  <SettingOutlined className="setting-icon" />*/}
          {/*</div>*/}
        </div>
      </div>

      {/* <Col span={10}>
                    <Menu theme="dark" mode="horizontal" style={{float: 'right'}}>
                        <Menu.Item key="1">
                            <Dropdown overlay={userSettingsMenu} trigger={['click']} placement="bottomRight">
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <Avatar shape="circle" icon={<UserOutlined />} size='small'/> {userFullName}
                                </a>
                            </Dropdown>
                        </Menu.Item>
                    </Menu>
                </Col> */}
    </Header>
  );
};
export default HeaderX;
