import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { useConnection } from "./ApplicationContext";
import AuthorizationList from "../Constant/AuthorizationList";

export enum RoleTypes {
  ADMIN = "ADMIN",
  CASHIER = "CASHIER",
  MANAGER = "MANAGER",
}

export type UserProps = {
  userName: string;
  role: RoleTypes;
  lastName: string;
  firstName: string;
  id: string;
};
export type UserContextProps = {
  userInfo?: UserProps;
  setUserInfo: (val?: UserProps) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
  isAuthorized: (val?: number) => boolean;
};
const userContext = React.createContext<{ user?: UserContextProps }>({});
export type ChildrenProp = { children: ReactNode };

export const UserContextProvider: FC<ChildrenProp> = ({
  children,
}: ChildrenProp) => {
  const [userInfo, setUserInfoState] = useState<UserProps>();
  const { token, updateToken } = useConnection();
  // const history = useHistory();
  const logout = async () => {
    setUserInfoState(undefined);
    await localStorage.removeItem("user");
    await updateToken(undefined);
    window.location.href = "/";
    // history.push("/");
  };
  const setUserInfo = async (value?: UserProps) => {
    if (value) {
      setUserInfoState(value);
      localStorage.setItem("user", JSON.stringify(value));
    } else {
      const user = localStorage.getItem("user");
      if (user) {
        setUserInfoState(JSON.parse(user));
      }
    }
  };
  const isAuthorized = (val?: number): boolean => {
    if (val !== undefined) {
      if (userInfo && userInfo.role) {
        return (
          AuthorizationList[userInfo.role].findIndex((vl) => vl === val) > -1
        );
      }
      return false;
    }
    return true;
  };
  useEffect(() => {
    setUserInfo();
  }, []);
  const isAuthenticated = useCallback((): boolean => {
    let tokenX: string | null;
    if (token) {
      tokenX = token;
    } else {
      tokenX = localStorage.getItem("token");
    }
    try {
      if (tokenX) {
        const { exp } = jwt_decode(tokenX) as any;
        return Date.now() < exp * 1000;
      }
      return false;
    } catch (err) {
      return false;
    }
  }, [token]);
  return (
    <userContext.Provider
      value={{
        user: {
          userInfo,
          setUserInfo,
          isAuthenticated,
          isAuthorized,
          logout,
        },
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export default userContext;
export const useUser = () => {
  const context = useContext(userContext);
  return context?.user!;
};
