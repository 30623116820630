import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, Select, Space } from "antd";
import { useBusiness } from "../../services/BuisnessService";

export interface FilterProps {
  onFinishSearch: (val?: any) => void;
  onReset: () => void;
  hideOrderSearch?: boolean;
}
export const FilterProfessional: FC<FilterProps> = ({
  onFinishSearch,
  onReset,
  hideOrderSearch,
}) => {
  const [form] = Form.useForm();
  const { customers, fetchCustomers } = useBusiness();
  const onResetForm = () => {
    form.resetFields();
    onReset();
  };
  useEffect(() => {
    fetchCustomers();
  }, []);
  return (
    <Form layout="inline" form={form} onFinish={onFinishSearch}>
      {!hideOrderSearch && (
        <Form.Item name="_id">
          <Input placeholder="Enter Order ID" />
        </Form.Item>
      )}
      {/* <Form.Item name="shopName"> */}
      {/*  <Input placeholder="Enter Shop Name" /> */}
      {/* </Form.Item> */}
      <Form.Item name="customer">
        <Select
          placeholder="Select Customer"
          showSearch
          style={{ minWidth: 200, maxWidth: 500 }}
          filterOption={(input, option) => {
            const x: string = (option?.children as any) ?? "";
            return x.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {customers.map((vl, index) => (
            <Select.Option key={index.toString()} value={vl._id}>
              {vl.shopName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* <Form.Item> */}
      {/*  <Input placeholder="Enter Date" /> */}
      {/* </Form.Item> */}
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button onClick={onResetForm}>Reset</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
