import React, { FC } from "react";
import { Select } from "antd";
import { IItem } from "../../Constant/ItemModel";

type OtherItemSelectProps = {
  options: IItem[];
  value?: any;
  onChange?: (val: any) => void;
};
export const OtherItemSelect: FC<OtherItemSelectProps> = ({
  options,
  value,
  onChange,
}) => {
  const renderSelectItemTypes = () => {
    return (
      options &&
      options.map((vl: IItem, key: number) => (
        <Select.Option key={key.toString()} value={vl.value}>
          {vl.name}
        </Select.Option>
      ))
    );
  };
  const onChangeValue = (val: string[]) => {
    console.log("mullll ", val);
    if (options !== undefined && options.length > 0 && onChange) {
      const temp = val.map((vl: string) => {
        const fil = options.find((item) => item.value === vl);
        return {
          value: fil?.price,
          key: vl,
        };
      });
      console.log("temptemptemptemp ", temp);
      onChange(temp);
    }
  };
  const generateValue = () => {
    if (value) {
      return value.map((vl: any) => vl.key);
    }
    return undefined;
  };
  return (
    <Select
      value={generateValue()}
      onChange={onChangeValue}
      placeholder="Select Other"
      allowClear
      // style={{width: 100}}
      mode="multiple"
    >
      {renderSelectItemTypes()}
    </Select>
  );
};
