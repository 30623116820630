import React, { FC, useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import "./Styles.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useConnection } from "../../services/ApplicationContext";
import { useBusiness } from "../../services/BuisnessService";
import {
  IItemCode,
  IProfessionalOrderUpdateDTO,
} from "../../services/BusinessModel";
import { IItemData } from "../../components/Atoms/TagDictionary";
import { checkKey } from "../../utilities/Utilities";
import { Category, IItem, ItemTypesEnum } from "../../Constant/ItemModel";
import { OtherItemSelect } from "../../components/Atoms/OtherItemSelect";

export interface EditOrderModalProps {
  visible: boolean;
  onClose: (add: "add" | "edit", status?: "success" | "failed") => void;
  data?: IProfessionalOrderUpdateDTO;
  edit: boolean;
}
const UpdateOrder: FC<EditOrderModalProps> = (props: EditOrderModalProps) => {
  const { visible, onClose, data, edit } = props;
  const { post, patch } = useConnection();
  const {
    fetchCustomers,
    customers,
    itemCodes,
    allProfessionalItems,
    setAllProfessionalItems,
  } = useBusiness();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [frameName, setFrameName] = useState<string>();
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
    fetchCustomers();
  }, [data]);

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (allProfessionalItems) {
      const currentAmateurItems = [
        ...allProfessionalItems[ItemTypesEnum.FRAME],
        { name: frameName, value: frameName },
      ];
      // setItems([...items, frameName || `New item ${index++}`]);
      setAllProfessionalItems({
        ...allProfessionalItems,
        [ItemTypesEnum.FRAME]: currentAmateurItems as any,
      });
      setFrameName("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrameName(event.target.value);
  };
  const calculateTotal = (values: any) => {
    if (
      values &&
      Object.hasOwnProperty.call(values, "orderList") &&
      values?.orderList?.length > 0
    ) {
      const { orderList } = values;
      let total = 0;
      for (let i = 0; i < orderList.length; i += 1) {
        if (
          orderList[i] &&
          checkKey(orderList[i], "count") &&
          orderList[i].count > -1
        ) {
          let pricePerRow = 0;
          if (checkKey(orderList[i], "itemCode")) {
            const selectedItemCode = itemCodes.find(
              // eslint-disable-next-line no-underscore-dangle
              (itemValue: IItemCode) => itemValue._id === orderList[i].itemCode
            );

            if (
              selectedItemCode &&
              checkKey(selectedItemCode, "price") &&
              selectedItemCode?.price
            ) {
              pricePerRow = selectedItemCode.price;
            }
            // if (checkKey(orderList[i], "otherServices") && ) {
            //   const other: IItemData[] = orderList[i].otherServices;
            //
            //   for (let j = 0; j < other.length; j += 1) {
            //     pricePerRow += Number(other[j].value);
            //   }
            // }
          } else {
            if (checkKey(orderList[i], "photoPrice")) {
              pricePerRow = orderList[i].photoPrice;
            }
            if (checkKey(orderList[i], "framePrice")) {
              pricePerRow += orderList[i].framePrice;
            }
            if (checkKey(orderList[i], "laminatePrice")) {
              pricePerRow += orderList[i].laminatePrice;
            }
            if (checkKey(orderList[i], "otherServices")) {
              const other: IItemData[] = orderList[i].otherServices;

              for (let j = 0; j < other.length; j += 1) {
                pricePerRow += Number(other[j].value);
              }
            }
          }
          orderList[i].subtotal = pricePerRow * orderList[i].count;
          total += pricePerRow * orderList[i].count;
        }
      }
      form.setFieldsValue({ orderList });
      if (total > -1) {
        form.setFieldsValue({ total });
      }
    } else {
      form.setFieldsValue({ total: 0 });
      message.open({
        type: "error",
        content: "Please check related price inputs00",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };
  const deleteOrderListKeysWhenCheckboxChecked = (
    _: any,
    index: number,
    values: any
  ) => {
    const valuesTemp = { ...values };
    if (
      checkKey(_.orderList[index], "showLaminate") &&
      _.orderList[index].showLaminate === false
    ) {
      delete valuesTemp.orderList[index].laminatePrice;
      delete valuesTemp.orderList[index].laminateType;
    }
    if (
      checkKey(_.orderList[index], "showFrame") &&
      _.orderList[index].showFrame === false
    ) {
      delete valuesTemp.orderList[index].framePrice;
      delete valuesTemp.orderList[index].frameType;
    }
    form.setFieldsValue({ orderList: valuesTemp.orderList });
    setFormValues(valuesTemp);
    calculateTotal(valuesTemp);
  };
  const handleOnItemCodeChange = (
    orderList: any[],
    values: any,
    index: number
  ) => {
    const allFormData = { ...values };
    if (
      checkKey(orderList[index], "itemCode") &&
      orderList[index].itemCode !== ""
    ) {
      const selectedItemCode = itemCodes.find(
        // eslint-disable-next-line no-underscore-dangle
        (itemValue: IItemCode) => itemValue._id === orderList[index].itemCode
      );
      if (selectedItemCode) {
        if (checkKey(selectedItemCode, "frameType")) {
          allFormData.orderList[index].showFrame = true;
          allFormData.orderList[index].frameType = selectedItemCode.frameType;
          if (allProfessionalItems) {
            const frameData = allProfessionalItems[ItemTypesEnum.FRAME].find(
              (vl) =>
                vl.category === Category.PROFESSIONAL &&
                vl.value === selectedItemCode.frameType
            );
            // TODO remove or decide this if block
            // allFormData.orderList[index].framePrice = frameData?.price;
          }
        } else {
          allFormData.orderList[index].showFrame = false;
          allFormData.orderList[index].frameType = undefined;
        }
        if (checkKey(selectedItemCode, "laminateType")) {
          allFormData.orderList[index].showLaminate = true;
          allFormData.orderList[index].laminateType =
            selectedItemCode.laminateType;
          if (allProfessionalItems) {
            const laminateData = allProfessionalItems[
              ItemTypesEnum.LAMINATE
            ].find(
              (vl) =>
                vl.category === Category.PROFESSIONAL &&
                vl.value === selectedItemCode.laminateType
            );
            // TODO remove or decide this if block
            // allFormData.orderList[index].laminatePrice = laminateData?.price;
          }
        } else {
          allFormData.orderList[index].showLaminate = false;
          allFormData.orderList[index].laminateType = undefined;
        }
        if (checkKey(selectedItemCode, "price")) {
          allFormData.orderList[index].price = selectedItemCode.price;
        }
        if (checkKey(selectedItemCode, "otherServices")) {
          allFormData.orderList[index].otherServices =
            selectedItemCode.otherServices;
        }
        allFormData.orderList[index].size = selectedItemCode.photoSize;
        allFormData.orderList[index].subtotal = selectedItemCode.price;
        console.log("yooo ak  prooo", allFormData.orderList);
        form.setFieldsValue({ orderList: allFormData.orderList });
      }
    }
  };
  const onFormChange = async (_: any, values: any) => {
    if (values && checkKey(_, "orderList") && values?.orderList?.length > 0) {
      calculateTotal(values);
      const orderObj = Object.keys(_.orderList);
      if (orderObj && orderObj.length === 1) {
        const index = Number(orderObj[0]);
        // console.log("criii ", _.orderList[index], " index ", index);
        if (_.orderList[index]) {
          handleOnItemCodeChange(_.orderList, values, index);

          if (
            _.orderList.length === values.orderList.length - 1 &&
            Object.values(values.orderList[values.orderList.length - 1]).every(
              (v) => v === undefined
            )
          ) {
            setFormValues(values);
          } else {
            deleteOrderListKeysWhenCheckboxChecked(_, index, values);
          }
        } else {
          setFormValues(values);
        }
      }
    } else {
      if (checkKey(_, "orderList")) {
        form.setFieldsValue({ total: 0 });
      }

      setFormValues({ ...values, total: 0 });
    }
  };
  const onConfirm = async (values: any): Promise<void> => {
    console.log("order deta ", values);
    values.orderDate = new Date();
    setLoading(true);
    try {
      if (edit && data) {
        const entry = {
          ...data,
          ...values,
          payment: data?.originalPaymentDetails,
        };
        delete entry.key;
        delete entry.originalPaymentDetails;
        const result = await patch(`orders/professional/${data._id}`, entry);
        onClose("edit");
        if (result.status === 200) {
          message.open({
            type: "success",
            content: "Order Updated!",
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
          onClose("edit", "success");
        } else {
          message.open({
            type: "warning",
            content: "Order Update Failed!",
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
      } else {
        const entry = values;
        entry.orderStatus = "none";
        if (values.payment) {
          if (values.payment < values.total) {
            entry.paymentStatus = "advance";
            entry.payment = [
              { date: Number(Date.now()), amount: values.payment },
            ];
          } else {
            entry.paymentStatus = "completed";
            entry.payment = [
              { date: Number(Date.now()), amount: values.total },
            ];
          }
        } else {
          entry.paymentStatus = "none";
        }

        setLoading(true);

        const result = await post("orders/professional", entry);
        console.log("Location: ", result);
        if (result.status === 201) {
          message.open({
            type: "success",
            content: "Order created",
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
          onClose("add", "success");
        } else {
          message.open({
            type: "warning",
            content: result.message,
            duration: 2,
            style: { textAlign: "right", marginRight: 15, marginTop: 10 },
          });
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        // content: "Error occurred",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  const validateVisibility = (index: number, key: string): boolean => {
    if (
      formValues &&
      checkKey(formValues, "orderList") &&
      Array.isArray(formValues.orderList) &&
      formValues.orderList.length > 0 &&
      formValues.orderList[index] &&
      Object.hasOwnProperty.call(formValues.orderList[index], key)
    ) {
      return formValues.orderList[index][key];
    }
    return false;
  };

  const formItemLayout = {
    labelCol: {
      span: 6,
    },
  };

  const itemCodeList = () => {
    return itemCodes
      .filter((vl) => vl.category === Category.PROFESSIONAL)
      .map((item: any) => (
        <Select.Option key={item._id} value={item._id}>
          {item._id}
        </Select.Option>
      ));
  };
  const renderSelectItemTypes = (val: ItemTypesEnum) => {
    return (
      allProfessionalItems &&
      allProfessionalItems[val].map((vl: IItem, key: number) => (
        <Select.Option key={key.toString()} value={vl.value}>
          {vl.name}
        </Select.Option>
      ))
    );
  };
  console.log("orf data prof ", data);
  return (
    <>
      <Modal
        title={edit ? `Update Oder` : `Add Order`}
        centered
        visible={visible}
        onCancel={() => onClose("add")}
        width="100%"
        footer={null}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="prof-order"
          hideRequiredMark={false}
          initialValues={data}
          onFinish={onConfirm}
          scrollToFirstError
          autoComplete="off"
          style={{ justifyContent: "center" }}
          className="ant-advanced-search-form"
          layout="vertical"
          onValuesChange={onFormChange}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="customer"
                label="Customer"
                className="display"
                rules={[
                  {
                    required: true,
                    message: "Please enter customer's Name!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Customer"
                  showSearch
                  filterOption={(input, option) => {
                    const x: string = (option?.children as any) ?? "";
                    return x.toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  {customers.map((vl, index) => (
                    <Select.Option key={index.toString()} value={vl._id}>
                      {vl.shopName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item name="note" label="Notes" className="display">
                <TextArea showCount maxLength={200} />
              </Form.Item>
            </Col>
          </Row>

          <Collapse bordered={false} defaultActiveKey={["1"]}>
            <Collapse.Panel header="Order list [Click to collapse]" key="1">
              <Form.List
                name="orderList"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length === 0) {
                        return Promise.reject(
                          new Error("Order list cannot be empty")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => {
                  return (
                    <>
                      {fields.map((field, index) => (
                        <Row
                          justify="space-around"
                          className="row-container-bs"
                          key={index.toString()}
                          gutter={[16, 16]}
                        >
                          <Col span={6}>
                            <Form.Item
                              labelCol={{ span: 12 }}
                              label="Item Codes"
                              name={[field.name, "itemCode"]}
                            >
                              <Select
                                placeholder="Select Item Codes"
                                showSearch
                                allowClear
                              >
                                {itemCodeList()}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              labelCol={{ span: 12 }}
                              name={[field.name, "size"]}
                              label="Photo Size"
                              rules={[
                                {
                                  // required: true,
                                  message: "Missing Size!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select Size"
                                showSearch
                                allowClear
                              >
                                {renderSelectItemTypes(ItemTypesEnum.PHOTO)}
                              </Select>
                            </Form.Item>
                            {validateVisibility(index, "itemCode") ===
                              undefined && (
                              <Form.Item
                                name={[field.name, "photoPrice"]}
                                label="Print Price"
                                className="order-item"
                                labelCol={{ span: 12 }}
                                // rules={[
                                //   {
                                //     // required: true,
                                //     message: "Missing photo price!",
                                //   },
                                // ]}
                              >
                                <InputNumber />
                              </Form.Item>
                            )}
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              name={[field.name, "showFrame"]}
                              className="order-item"
                              labelCol={{ span: 12 }}
                              valuePropName="checked"
                            >
                              <Checkbox>Add Frame</Checkbox>
                            </Form.Item>
                            {validateVisibility(index, "showFrame") && (
                              <>
                                <Form.Item
                                  name={[field.name, "frameType"]}
                                  label="Frame Type"
                                  className="order-item"
                                  labelCol={{ span: 18 }}
                                >
                                  <Select
                                    allowClear
                                    options={
                                      allProfessionalItems &&
                                      allProfessionalItems[
                                        ItemTypesEnum.FRAME
                                      ].map((vl: IItem, key: number) => ({
                                        label: vl.name,
                                        value: vl.value,
                                      }))
                                    }
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <Divider style={{ margin: "8px 0" }} />
                                        <Space style={{ padding: "0 8px 4px" }}>
                                          <Input
                                            placeholder="Please enter item"
                                            ref={inputRef}
                                            value={frameName}
                                            onChange={onNameChange}
                                          />
                                          <Button
                                            type="text"
                                            icon={<PlusOutlined />}
                                            onClick={addItem}
                                          >
                                            Add item
                                          </Button>
                                        </Space>
                                      </>
                                    )}
                                  />
                                </Form.Item>
                                {validateVisibility(index, "framePrice") && (
                                  <Form.Item
                                    name={[field.name, "framePrice"]}
                                    label="Price"
                                    className="order-item"
                                    labelCol={{ span: 12 }}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                )}
                              </>
                            )}
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              valuePropName="checked"
                              name={[field.name, "showLaminate"]}
                              className="order-item"
                              labelCol={{ span: 18 }}
                              // wrapperCol={{ offset: 8, span: 16 }}
                            >
                              <Checkbox>Add Laminate</Checkbox>
                            </Form.Item>
                            {validateVisibility(index, "showLaminate") && (
                              <>
                                <Form.Item
                                  name={[field.name, "laminateType"]}
                                  label="Laminate Type"
                                  className="order-item"
                                  labelCol={{ span: 18 }}
                                  // dependencies={["orderList", index, "showLaminate"]}
                                >
                                  <Select allowClear>
                                    {renderSelectItemTypes(
                                      ItemTypesEnum.LAMINATE
                                    )}
                                  </Select>
                                </Form.Item>
                                {validateVisibility(index, "laminatePrice") && (
                                  <Form.Item
                                    name={[field.name, "laminatePrice"]}
                                    label="Price"
                                    className="order-item"
                                    labelCol={{ span: 18 }}
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                )}
                              </>
                            )}
                          </Col>
                          <Col>
                            {allProfessionalItems && (
                              <Form.Item
                                name={[field.name, "otherServices"]}
                                className="order-item"
                                labelCol={{ span: 18 }}
                              >
                                <OtherItemSelect
                                  options={
                                    allProfessionalItems[ItemTypesEnum.OTHER]
                                  }
                                />
                              </Form.Item>
                            )}
                            <Form.Item
                              name={[field.name, "count"]}
                              label="Count"
                              className="order-item"
                              labelCol={{ span: 18 }}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing count!",
                                },
                              ]}
                              initialValue={1}
                            >
                              <InputNumber />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name={[field.name, "subtotal"]}
                              label="Sub total"
                              className="order-item"
                              labelCol={{ span: 18 }}
                              initialValue={0}
                            >
                              <InputNumber />
                            </Form.Item>
                          </Col>
                          <MinusCircleOutlined
                            className="minus-icon"
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        </Row>
                      ))}
                      <Button onClick={() => add()}>ADD</Button>
                      <Form.ErrorList errors={errors} />
                    </>
                  );
                }}
              </Form.List>
            </Collapse.Panel>
          </Collapse>
          <Divider />
          <Row>
            {/* <Form.Item>
              <Button
                type="primary"
                style={{ marginRight: "15px" }}
                // onClick={calculateTotal}
              >
                Calculate Total
              </Button>
            </Form.Item> */}
            <Form.Item
              name="total"
              label="Total"
              className="order-item"
              labelCol={{ span: 24, offset: 12 }}
              wrapperCol={{ span: 24, offset: 4 }}
              rules={[
                {
                  required: true,
                  message: "Press Calculate Price Button to get the Total",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Row>
          <Row>
            {/* <Col className="gutter-row" span={12}>
              <Form.Item
                name="payment"
                label="Payment"
                className="order-item"
                labelCol={{ span: 24 }}
              >
                <InputNumber disabled={edit} />
              </Form.Item>
            </Col> */}
            {data && data.discount && (
              <Col className="gutter-row" span={6}>
                <Form.Item
                  name="discount"
                  label="Discount"
                  className="order-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <InputNumber disabled />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item className="modal-button ant-form-item-control-input-content">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ margin: "15px" }}
            >
              {edit ? `Edit Order` : `Add Order`}
            </Button>
            <Button
              loading={loading}
              danger
              type="primary"
              style={{ margin: "15px" }}
              onClick={() => onClose("add")}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateOrder;
