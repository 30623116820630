import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export interface Response<T = any> {
  data: T;
  statusText: string;
  status?: number;
  headers: any;
  config?: AxiosRequestConfig;
  request?: any;
  message: string;
}

export type Methods = "get" | "post" | "delete" | "put" | "patch";

export type ConnectionProps = {
  post<T = any, R = Response<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
  get<T = any, R = Response<T>>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<R>;
  delete<T = any, R = Response<T>>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<R>;
  put<T = any, R = Response<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
  patch<T = any, R = Response<T>>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
  updateToken: (token?: string) => void;
  token?: string;
};
const ApplicationContext = React.createContext<{
  connection?: ConnectionProps;
}>({});

type ApplicationContextProviderProps = {
  serverSideUrl: string;
  children: ReactNode;
};
export const ApplicationContextProvider: FC<ApplicationContextProviderProps> = (
  props: ApplicationContextProviderProps
) => {
  const [token, setToken] = useState<string>();
  const { serverSideUrl, children } = props;

  useEffect(() => {
    const timer = setInterval(async () => {
      const newToken: any = await localStorage.getItem("token");
      if (token !== newToken) {
        setToken(newToken);
      }
    }, 3000);
    if (token) {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [token]);

  const send = useCallback(
    (
      method: Methods,
      path: string,
      data?: any,
      config?: AxiosRequestConfig
    ) => {
      return new Promise((resolve, reject) => {
        const url = `${serverSideUrl}/${path}`;
        let headers: any;
        if (token) {
          headers = { authorization: `Bearer ${token.toString()}` };
        } else {
          const tk = localStorage.getItem("token");
          if (tk) {
            headers = {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            };
          }
        }
        axios({
          method,
          url,
          data,
          headers,
          ...config,
        })
          .then((response: AxiosResponse) => {
            if (response.status) {
              resolve({
                status: response.status,
                data: response.data,
                statusText: "SUCCESS",
                message: response.data.message,
              });
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                status: 500,
                statusText: "UNKNOWN",
                message: "Something went wrong",
              });
            }
          })
          .catch((e: any) => {
            console.log(
              "cach ",
              e,
              " __- ",
              e?.response?.data,
              " ++ ",
              e?.message
            );
            if (e.response) {
              if (e.response.status) {
                if (e.response.data.message === "jwt expired") {
                  localStorage.removeItem("token");
                  window.location.reload();
                }
                console.log("fds ", e.response.data);
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({
                  status: e.response.status,
                  statusText: "ERROR",
                  message: e.response.data.message,
                });
              } else {
                console.log("fds one ", e?.response?.data);
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({
                  statusText: "ERROR",
                  message: "Something went wrong",
                });
              }
            } else {
              console.log("three ", e?.response?.data);
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                statusText: "ERROR",
                message: "Network error",
              });
            }
          });
      }) as any;
    },

    [token, serverSideUrl]
  );

  const post = useCallback(
    (path: string, data?: any, config?: AxiosRequestConfig) => {
      return send("post", path, data, config);
    },
    [send]
  );
  const put = useCallback(
    (path: string, data?: any, config?: AxiosRequestConfig) => {
      return send("put", path, data, config);
    },
    [send]
  );
  const get = useCallback(
    (path: string, config?: AxiosRequestConfig) => {
      return send("get", path, undefined, config);
    },
    [send]
  );
  const patch = useCallback(
    (path: string, data?: any, config?: AxiosRequestConfig) => {
      return send("patch", path, data, config);
    },
    [send]
  );
  const del = useCallback(
    (path: string, config?: AxiosRequestConfig) => {
      return send("delete", path, config);
    },
    [send]
  );

  const updateToken = useCallback(async (tokenx?: string) => {
    if (tokenx) {
      localStorage.setItem("token", tokenx);
      setToken(tokenx);
    } else {
      localStorage.removeItem("token");
      setToken(undefined);
    }
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        connection: { post, put, get, patch, delete: del, updateToken, token },
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContext;
export const useConnection = () => {
  const context = useContext(ApplicationContext);

  return context.connection!;
};
