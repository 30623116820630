import React, { useState, FC, useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  message,
  Modal,
  Row,
  Table,
} from "antd";
import { useParams } from "react-router";
import { useConnection } from "../../services/ApplicationContext";
import {
  IPayment,
  IProfessionalOrderTableEntry,
} from "../../services/BusinessModel";

interface DataType extends Omit<IProfessionalOrderTableEntry, "key"> {
  key: React.Key;
}
export interface UnpaidOrderTableProps {
  visible: boolean;
  onClose: (status?: "success" | "failed") => void;
}
const UnpaidOrderTable: FC<UnpaidOrderTableProps> = (
  props: UnpaidOrderTableProps
) => {
  const [checklist, setCheckList] = useState<DataType[]>([]);
  const [selectedRows, setSelectedRows] = useState<DataType[]>([]);
  const [money, setMoney] = useState<number>(0);
  const [balance, setBalance] = useState<number>();
  const { visible, onClose } = props;
  const { id } = useParams<{ id?: string }>();
  const { post, patch } = useConnection();
  const [form] = Form.useForm();

  const calculateTotal = (inputList?: DataType[]) => {
    let total = 0;
    let selectedList = [...selectedRows];
    if (inputList) {
      selectedList = inputList;
    }
    if (selectedList.length > 0) {
      total = selectedList
        .map((vl) => vl.total)
        .reduce((prev, curr) => prev + curr);
    }
    return total;
  };
  const calculateToSettle = (record: DataType, items?: IPayment[]) => {
    let currentlyPaid = 0;
    if (items && items.length > 0) {
      currentlyPaid = items
        .map((vl) => vl.amount)
        .reduce((prev, curr) => prev + curr);
    }
    return record.total - currentlyPaid;
  };
  const calculateTotalToSettle = (inputList?: DataType[]) => {
    let selectedList = [...selectedRows];
    if (inputList) {
      selectedList = inputList;
    }
    if (selectedList && selectedList.length > 0) {
      return selectedList?.reduce(
        (p, c) => calculateToSettle(c, c.payment) + p,
        0
      );
    }
    return 0;
  };
  const validatePayment = () => {
    if (form.getFieldValue("payment") < calculateTotalToSettle()) {
      return (
        <>
          <Alert
            message="Payment Amount is not Sufficient!"
            type="error"
            showIcon
          />
        </>
      );
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedItems: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedItems
      );
      // if( calculateTotal(selectedItems) <= money){
      setSelectedRows(selectedItems);
      // }
    },
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "_id",
      render: (text: string) => (
        <span style={{ color: "#faad14" }} key={text}>
          {text}
        </span>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
    },
    {
      title: "Paid",
      dataIndex: "payment",
      key: "payment",
      render: (items: IPayment[]) => {
        let currentlyPaid = 0;
        if (items.length > 0) {
          currentlyPaid = items
            .map((vl) => vl.amount)
            .reduce((prev, curr) => prev + curr);
        }
        return <span key={currentlyPaid}>{currentlyPaid}</span>;
      },
    },
    {
      title: "To settle",
      dataIndex: "payment",
      render: (items: IPayment[], record: any) => {
        // let currentlyPaid = 0;
        // if (items.length > 0) {
        //   currentlyPaid = items
        //     .map((vl) => vl.amount)
        //     .reduce((prev, curr) => prev + curr);
        // }
        return (
          <span style={{ color: "#cc325c" }} key={record._id}>
            {calculateToSettle(record, items)}
          </span>
        );
        // return <span style={{ color: "#cc325c" }} key={currentlyPaid}>{record.total - currentlyPaid}</span>;
      },
    },
  ];

  const fetchUnpaidOrders = async () => {
    try {
      const result = await post("orders/filter-professional", {
        skip: 0,
        limit: 100,
        filters: {
          customer: id,
          paymentStatus: { $ne: "completed" },
        },
        sort: {
          orderDate: 1,
        },
      });
      console.log(" ordr list filterted ", result);
      if (result && result.data && result.status === 200) {
        if (result.data.data && result.data.data.length > 0) {
          setCheckList(
            result.data.data.map((vl: DataType) => ({
              ...vl,
              key: vl._id,
            }))
          );
        } else {
          setCheckList([]);
        }
      }
    } catch (e) {
      console.log(" error ** ", e);
    }
  };

  useEffect(() => {
    if (id) {
      fetchUnpaidOrders();
    }
  }, [id]);
  const getCheckboxProps = (record: DataType) => ({
    disabled:
      money <= 0 ||
      (selectedRows.findIndex((vl) => vl._id === record._id) === -1 &&
        money <= calculateTotalToSettle()), // Column configuration not to be checked
    // name: record.name,
  });
  const onConfirm = async (values: any) => {
    try {
      const amountChoosedToPay = calculateTotalToSettle();
      setBalance(Number(values.payment) - amountChoosedToPay);
      const orderList = selectedRows.map((item: any) => {
        const amount = calculateToSettle(item, item.payment);
        item.payment.push({ amount, date: Date.now() });
        // eslint-disable-next-line no-param-reassign
        item.newPayment = amount;
        return item;
      });
      const result = await patch("orders/professional/settle", orderList);
      console.log("after sss ", result);
      if (result.status === 200) {
        onClose("success");
      } else {
        message.info("Error");
      }
    } catch (e) {
      console.log("ee ", e);
      message.error("Error");
    }
  };
  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => onClose()}
        title="Manage Unpaid Orders"
        centered
        width="40%"
        footer={null}
      >
        <Form form={form} onFinish={onConfirm}>
          <Row gutter={[24, 24]}>
            <Col>
              <Form.Item name="payment" label="Payment">
                <InputNumber
                  step="0.01"
                  value={money}
                  stringMode
                  onChange={(value: any) => setMoney(value)}
                />
              </Form.Item>
            </Col>
            <Col style={{ marginBottom: 10 }}>
              {selectedRows && <>{validatePayment()}</>}
            </Col>
          </Row>
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
              getCheckboxProps,
            }}
            columns={columns}
            dataSource={checklist}
            onChange={(vl) => console.log("fffuucker ", vl)}
          />
          <Divider />
          <Row gutter={[24, 24]}>
            <Col span={6}>
              <span>Total : {calculateTotalToSettle()} LKR</span>
            </Col>
            {selectedRows &&
              form.getFieldValue("payment") >= calculateTotalToSettle() && (
                <Button // loading={loading}
                  type="primary"
                  htmlType="submit"
                >
                  Pay
                </Button>
              )}
            {balance && (
              <span style={{ marginLeft: 20 }}>Balance : {balance} LKR</span>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default UnpaidOrderTable;
