import React, { FC } from "react";
import { Collapse } from "antd";
import MainLayout from "../../components/MainLayout";
import ItemCode from "./ItemCode";
import Items from "./Items";

const AdminPage: FC = () => {
  const { Panel } = Collapse;

  return (
    <MainLayout type="primary" title="ItemCode">
      <Collapse accordion bordered={false}>
        <Panel header="Add Items" key="1">
          <Items />
        </Panel>
        <Panel header="Create Item Code" key="2">
          <ItemCode />
        </Panel>
      </Collapse>
    </MainLayout>
  );
};
export default AdminPage;
