import React, { FC, useCallback, useEffect, useState } from "react";
import { Card, Col, Collapse, DatePicker, Row, Statistic, Table } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import moment from "moment";
import { IPayment, ISalesStats } from "../../../services/BusinessModel";
import { Category } from "../../../Constant/ItemModel";
import { useConnection } from "../../../services/ApplicationContext";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

type RangeValue = string[] | null;

type SalesSummaryProps = {
  data: ISalesStats;
  type: Category;
};
export const SalesCard: FC<SalesSummaryProps> = ({ data, type }) => {
  const getTotalSalesIncome = () => {
    if (data.newOrders.length > 0) {
      return data.newOrders
        .map((val: any) => val.total)
        .reduce((prev: any, curr: any) => prev + curr) as number;
    }
    return 0;
  };
  const getTotalSalesPayment = () => {
    if (data.newOrders.length > 0) {
      const list = data.newOrders.map((val: any) => val?.payment).flat();
      if (list?.length > 0) {
        return list
          .map((val: IPayment) => val?.amount)
          .reduce((prev: any, curr: any) => prev + curr) as number;
      }
      return 0;
    }
    return 0;
  };
  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Card>
          <Statistic
            title="Orders"
            value={data.numberOfNewOrders}
            valueStyle={{ color: "#3f8600" }}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Orders Completed"
            value={data.numberOfOrdersCompleted}
            valueStyle={{ color: "#3f8600" }}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Total Sales"
            value={getTotalSalesIncome()}
            precision={2}
            valueStyle={{ color: "#3f8600" }}
            suffix="LKR"
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Payments received"
            value={getTotalSalesPayment()}
            precision={2}
            valueStyle={{ color: "#3f8600" }}
            suffix="LKR"
          />
        </Card>
      </Col>
    </Row>
  );
};

export const SalesSummary: FC<{ type: Category }> = ({ type }) => {
  const { post, get } = useConnection();
  const [sales, setSales] = useState<any>();
  const [monthlySales, setMonthlySales] = useState<any>();

  const getSalesStats = useCallback(async () => {
    try {
      const daily = await post(`orders/${type}/statistics`);
      setSales(daily.data.data);
    } catch (e) {
      console.log("error sales", e);
    }
  }, [post]);
  const getMonthlySalesStats = useCallback(
    async (dateString: [string, string]) => {
      try {
        const daily = await post(`orders/${type}/statistics`, {
          dateFrom: dateString[0],
          dateTo: dateString[1],
        });
        setMonthlySales(daily.data.data);
      } catch (e) {
        console.log("error sales", e);
      }
    },
    [post]
  );
  const onChangeDateRange = (val: any, dateString: [string, string]) => {
    getMonthlySalesStats(dateString);
  };
  useEffect(() => {
    getSalesStats();
  }, []);
  const columns = [
    {
      title: "Order Id",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Order status",
      dataIndex: "orderStatus",
      key: "orderStatus",
    },
    {
      title: "Payment status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Payments",
      dataIndex: "total",
      render: (_: any, val: any) => (
        <span>
          {val?.payment.length > 0
            ? val?.payment
                .map((vl: IPayment) => vl?.amount)
                .reduce((prev: number, curr: number) => prev + curr)
            : 0}
        </span>
      ),
      key: "payment",
    },
    {
      title: "Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (_: any, val: any) => (
        <span>
          {moment.utc(val.orderDate).local().format("YYYY MMM DD  -  HH:mm")}
        </span>
      ),
    },
  ];
  return (
    <Collapse bordered={false}>
      <Panel header={type} key="1">
        <h3>Daily Sales</h3>
        {sales && <SalesCard data={sales} type={Category.AMATEUR} />}

        <h3>Monthly Sales</h3>

        <div style={{ marginBottom: 8 }}>
          <RangePicker
            // value={value ? [moment(value[0]), moment(value[1])] : undefined}
            format="YYYY-MM-DD"
            onChange={onChangeDateRange}
          />
        </div>
        {monthlySales && (
          <SalesCard data={monthlySales} type={Category.AMATEUR} />
        )}
      </Panel>
      {monthlySales && (
        <Panel header="Table" key="2">
          <Table columns={columns} dataSource={monthlySales.newOrders} />
        </Panel>
      )}
    </Collapse>
  );
};
