import { IAmateurOrderRecord } from "../services/AmateurModel";
import { IProfessionalOrderTableEntry } from "../services/BusinessModel";

export const checkKey = (obj: any, key: string): boolean => {
  return Object.hasOwnProperty.call(obj, key) && obj[key] !== undefined;
};

export const fillSubTotals = (
  record: IProfessionalOrderTableEntry | IAmateurOrderRecord
) => {
  if (checkKey(record, "orderList")) {
    const { orderList } = record;
    orderList.forEach((element, index) => {
      let subTotal = 0;
      if (checkKey(element, "photoPrice") && element.photoPrice > 0) {
        subTotal += element.photoPrice;
      }
      if (
        checkKey(element, "framePrice") &&
        element.framePrice &&
        element.framePrice > 0
      ) {
        subTotal += element.framePrice as number;
      }
      if (
        checkKey(element, "editPrice") &&
        element.editPrice &&
        element.editPrice > 0
      ) {
        subTotal += element.editPrice as number;
      }
      if (
        checkKey(element, "laminatePrice") &&
        element.laminatePrice &&
        element.laminatePrice > 0
      ) {
        subTotal += element.laminatePrice as number;
      }
      if (
        checkKey(element, "otherServices") &&
        Array.isArray(element.otherServices) &&
        element.otherServices.length > 0
      ) {
        subTotal += element.otherServices
          .map((vl) => Number(vl.value))
          .reduce((prev, curr) => prev + curr);
      }
      orderList[index].subTotal = subTotal * element.count;
    });
    record.orderList = orderList;
    return record;
  }
};
