import React, { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { ApplicationContextProvider } from "./services/ApplicationContext";
import { UserContextProvider } from "./services/UserContext";
import { BusinessContextProvider } from "./services/BuisnessService";
import MainRouter from "./MainRouter";

const App: FC = () => {
  return (
    <ApplicationContextProvider
      serverSideUrl={ process.env.REACT_APP_API_BASE_URL || 'http://localhost:4000/api/v1'}
    >
      <UserContextProvider>
        <BusinessContextProvider>
          <Router>
            <MainRouter />
          </Router>
        </BusinessContextProvider>
      </UserContextProvider>
    </ApplicationContextProvider>
  );
};

export default App;
