import React, { FC } from "react";
import { Route } from "react-router-dom";
import Login from "./pages/LoginPage/Login";

import PrivateRoute from "./PrivateRoute";
import Professional from "./pages/Professional/Professional";
import Profile from "./pages/Professional/CustomerProfile";
import ProfessionalOrders from "./pages/ProfessionalOrders/ProfessionalOrder";
import AmateurOrder from "./pages/AmateurOrders/AmateurOrder";
import UnAuthorized from "./pages/LoginPage/UnAuthorized";
import AdminPage from "./pages/Admin/AdminPage";
import Sales from "./pages/Admin/Sales/SalesCharts";

const MainRouter: FC = () => {
  return (
    <>
      <Route exact path="/" component={Login} />
      <Route exact path="/professional" component={Professional} />
      <Route exact path="/profile/:id" component={Profile} />
      <Route exact path="/adminpage" component={AdminPage} />
      <Route exact path="/sales" component={Sales} />
      <PrivateRoute exact path="/orders" component={AmateurOrder} />
      <PrivateRoute
        exact
        path="/professional-orders"
        component={ProfessionalOrders}
      />
      <PrivateRoute exact path="/unauthorized" component={UnAuthorized} />
    </>
  );
};
export default MainRouter;
