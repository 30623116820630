import React, { FC } from "react";
import { Layout } from "antd";
import Header from "./Header/HeaderX";
import Sider from "./Sider/SiderX";

const { Content } = Layout;

export interface MainLayoutProps {
  type: "primary" | "minimal";
  title?: string;
}
const MainLayout: FC<MainLayoutProps> = (props) => {
  const { type, title, children } = props;

  const renderMinimal = () => {
    return (
      <Layout>
        <Layout>
          <Content
            className="site-layout-background"
            style={{ padding: "0 24px 24px" }}
          >
            <div className="content-container">
              {title && (
                <div className="content-header-container">
                  <h2 className="h2-user-management">{title}</h2>
                </div>
              )}
              <div>{children}</div>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  };

  const renderPrimary = () => {
    return (
      <>
        <Layout>
          <Header />
          <Layout>
            <Sider />
            <Content
              className="site-layout-background"
              style={{ padding: "0 24px 24px" }}
            >
              <div className="content-container">
                {title && (
                  <div className="content-header-container">
                    <h2 className="h2-user-management">{title}</h2>
                  </div>
                )}
                <div>{children}</div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </>
    );
  };
  switch (type) {
    case "minimal":
      return renderMinimal();
    case "primary":
      return renderPrimary();
    default:
      return renderPrimary();
  }
};

export default MainLayout;
