import React, {
  FC,
  useState,
  useRef,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import moment from "moment";
import "./Styles.css";
import { DollarOutlined, FieldTimeOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { useConnection } from "../../services/ApplicationContext";
import {
  IAmateurOrderFormEntry,
  IAmateurOrderRecord,
} from "../../services/AmateurModel";
import { fillSubTotals } from "../../utilities/Utilities";

export interface PaymentHandlerProps {
  data: IAmateurOrderRecord;
  visible: boolean;
  onClose: (val?: IAmateurOrderFormEntry) => void;
}
const orderStatusList = [
  { label: "None", value: "none" },
  { label: "Completed", value: "completed" },
  { label: "In-Progress", value: "in-progress" },
];
const PaymentHandler: FC<PaymentHandlerProps> = (
  props: PaymentHandlerProps
) => {
  const { visible, onClose, data: order } = props;
  const { post, get, patch } = useConnection();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const componentRef = useRef<HTMLDivElement>(null);
  const [dueNode, setDueNode] = useState<ReactNode>();
  const [dueNodeBill, setDueNodeBill] = useState<ReactNode>();
  const [payment, setPayment] = useState<number>(0);
  const [change, setChange] = useState<number>(0);
  const [discountState, setDiscountState] = useState<number>();
  const [
    orderAfterUpdate,
    setOrderAfterUpdate,
  ] = useState<IAmateurOrderFormEntry>();
  const data: IAmateurOrderRecord = useMemo(() => {
    if (orderAfterUpdate) {
      const temp = form.getFieldsValue();
      console.log(" order in memo 1", orderAfterUpdate);
      console.log(" order in memo 2", {
        ...order,
        ...orderAfterUpdate,
        ...temp,
      });
      return { ...order, ...orderAfterUpdate, ...temp };
    }
    return order;
  }, [order, orderAfterUpdate]);
  const discount = useMemo(() => {
    if (discountState) {
      return discountState;
    }
    if (data.discount) {
      return data.discount;
    }
    return 0;
  }, [data, discountState]);
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
  };
  const getCurrentPaidAmount = () => {
    let paymentX = 0;
    if (data.payment && data.payment.length > 0) {
      paymentX = data.payment
        .map((vl) => vl.amount)
        .reduce((prev, curr) => prev + curr);
    }
    return paymentX;
  };
  const fetchOrder = async (): Promise<any> => {
    try {
      return await get(`orders/amateur/${data._id}`);
    } catch (e: any) {
      console.log("error fetching a single order ", e);
      return undefined;
    }
  };

  const calculateDue = () => {
    let paymentx = 0;
    if (data.payment && data.payment.length > 0) {
      paymentx = getCurrentPaidAmount();
    }
    if (data.total - paymentx <= 0) {
      setDueNode(
        <div>
          <span className="data-label">Order Payments Completed! </span>
        </div>
      );
      setDueNodeBill(
        <div>
          <span className="data-labels">Order Payments Completed! </span>
        </div>
      );
    } else {
      let bill;
      console.log("discount in calc due ", discount);
      if (discount) {
        bill = data.total - paymentx - discount;
      } else {
        bill = data.total - paymentx;
      }
      setDueNode(
        <div>
          <span className="data-label">Payable Amount : </span>
          <span className="data-label">{bill} LKR</span>
        </div>
      );
      setDueNodeBill(
        <Row gutter={[8, 4]}>
          <Col span={8}>
            <span className="data-labels">Cash </span>
          </Col>
          <Col span={8}>{}</Col>
          <Col span={8}>
            <span className="data-labels">{paymentx} LKR</span>
          </Col>
          {(discount !== undefined || discount !== null) && discount > 0 && (
            <>
              <Col span={8}>
                <span className="data-labels">Discount </span>
              </Col>
              <Col span={8}>{}</Col>
              <Col span={8}>
                <span className="data-labels">{discount} LKR</span>
              </Col>
            </>
          )}
          <Col span={8}>
            <span className="data-labels">Balance </span>
          </Col>
          <Col span={8}>{}</Col>
          <Col span={8}>
            <span className="data-labels">{bill} LKR</span>
          </Col>
        </Row>
      );
    }
  };
  const calculateTotalCurrentPayment = () => {
    setPayment(getCurrentPaidAmount());
  };

  const onFinish = async (values: any) => {
    const entry = { ...values };
    if (data.payment.length > 0) {
      entry.payment = [
        ...data.payment,
        { amount: values.newPayment, date: Number(Date.now()) },
      ];
      if (isNaN(values.newPayment)) {
        delete entry.payment;
      }
    } else {
      entry.payment = [{ amount: values.newPayment, date: Number(Date.now()) }];
    }
    console.log("fiiiiii ", entry);
    // delete entry.payment;
    try {
      const result = await patch(`orders/amateurOrderPayment/${data._id}`, {
        ...data,
        ...entry,
      });
      console.log("pay result ", result);
      if (result.status === 200) {
        const remp = await fetchOrder();
        if (remp.status === 200) {
          const newRecord = fillSubTotals(remp.data);
          setOrderAfterUpdate(newRecord as any);
        }

        message.open({
          type: "success",
          content: "Order Updated!",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
        // onClose(entry);
      }
    } catch (e: any) {
      message.open({
        type: "error",
        content: e.message,
        // content: "Error occurred",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  const checkKey = (obj: any, key: string): boolean => {
    return Object.hasOwnProperty.call(obj, key) && obj[key] !== undefined;
  };
  const onFormChange = (diff: any, value: any) => {
    if (checkKey(value, "newPayment") && checkKey(value, "cash")) {
      if (
        checkKey(diff, "newPayment") ||
        checkKey(diff, "cash") ||
        checkKey(diff, "discount")
      ) {
        const val = Number(value.cash) - Number(value.newPayment);
        const currentPaid = getCurrentPaidAmount();
        // if (
        //   checkKey(value, "discount") &&
        //   currentPaid + value.newPayment > data.total - discount
        // ) {
        //   val += Number(value.discount);
        // }
        setChange(val);
      }
    }
  };

  useEffect(() => {
    calculateTotalCurrentPayment();
    calculateDue();
  }, [data, discount]);

  return (
    <Modal
      title="Order Details"
      centered
      visible={visible}
      onCancel={() => onClose()}
      width="90%"
      footer={null}
    >
      <div className="data-main-container">
        <div className="data-container">
          <Row justify="space-between">
            <Col span={12}>
              <span className="data-label">Customer Name: </span>{" "}
              <span className="data-text">{data.customerName}</span>
            </Col>
            <Col span={12}>
              <span className="data-label">Order Date: </span>{" "}
              <span className="data-text">
                {moment
                  .utc(data.orderDate)
                  .local()
                  .format("YYYY MMM DD  -  HH:mm")}
              </span>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={12}>
              <span className="data-label">Total: </span>{" "}
              <span className="data-text">{data.total} LKR</span>
            </Col>
            <Col span={12}>
              <span className="data-label">Notes: </span>{" "}
              <span className="data-text">{data.note ?? "none"}</span>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={12}>
              <span className="data-label">Mobile: </span>{" "}
              <span className="data-text">{data.contactNumber}</span>
            </Col>
            <Col span={12}>
              <span className="data-label">Address: </span>{" "}
              <span className="data-text">{data.address}</span>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={12}>
              <span className="data-label">Payment Status: </span>{" "}
              <span className="data-text">{data.paymentStatus}</span>
            </Col>
            <Col span={12}>
              <span className="data-label">Order Status: </span>{" "}
              <span className="data-text">{data.orderStatus}</span>
            </Col>
          </Row>
          {discount !== undefined && Number(discount) > 0 && (
            <Row justify="space-between">
              <Col span={12}>
                <span className="data-label">Discount: </span>{" "}
                <span className="data-text">{discount} LKR</span>
              </Col>
            </Row>
          )}
          <Divider />
          <span className="list-heading-pay">Payment History</span>
          <>
            {data.payment &&
              data.payment.map((val, index) => (
                <Row key={index.toString()} justify="start">
                  <span className="data-text-2">
                    <DollarOutlined
                      style={{ marginRight: 10, color: "#ea6950" }}
                    />
                    {val.amount} LKR
                    <FieldTimeOutlined
                      style={{
                        marginLeft: 60,
                        marginRight: 10,
                        color: "#ea6950",
                      }}
                    />{" "}
                    {moment
                      .utc(val.date)
                      .local()
                      .format("YYYY MMM DD  -  HH:mm")}
                  </span>
                </Row>
              ))}
            <Row key="total-payable" justify="start">
              {dueNode}
            </Row>
          </>
          <br />
          <span className="list-heading-pay">Handle Order</span>
          <Form
            {...formItemLayout}
            form={form}
            name="prof-order"
            initialValues={data}
            onFinish={onFinish}
            scrollToFirstError
            autoComplete="off"
            style={{ justifyContent: "center" }}
            className="ant-advanced-search-form"
            layout="vertical"
            onValuesChange={onFormChange}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="newPayment"
                  label="Payment"
                  className="order-item"
                  labelCol={{ span: 24 }}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Order Status"
                  name="orderStatus"
                  className="display"
                  rules={[
                    {
                      required: true,
                      message: "Please enter customer's Name!",
                    },
                  ]}
                >
                  <Select placeholder="Select Order Status">
                    {orderStatusList.map((vl, index) => (
                      <Select.Option key={index.toString()} value={vl.value}>
                        {vl.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label="Discount" name="discount" className="display">
                  <InputNumber
                    value={discount}
                    onChange={(val: any) => setDiscountState(val)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="cash"
                  label="Cash"
                  className="order-item"
                  labelCol={{ span: 24 }}
                  id="cash"
                >
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="data-label">Change </span> <br />
                <span className="data-text">{change}</span>
              </Col>
            </Row>
            <Form.Item className="modal-button ant-form-item-control-input-content">
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                style={{ margin: "15px" }}
              >
                Update
              </Button>
              <Button
                loading={loading}
                danger
                type="primary"
                style={{ margin: "15px" }}
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>

        <Divider type="vertical" className="divider-bill" />
        <div className="amatuer-bill-container">
          <div ref={componentRef} className="amatuer-bill-container-recipt">
            <h3
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              The Photo Expert
            </h3>
            <span className="data-labels" style={{ textAlign: "left" }}>
              No 20, Weerasundararama Mawatha, Balangoda.
            </span>
            <span className="data-labels" style={{ textAlign: "left" }}>
              Tel : 0452288130 | Email : thephotoexpert@gmail.com
            </span>
            <span style={{ color: "black", fontWeight: "bold" }}>
              Order Details
            </span>
            <Row gutter={[16, 2]}>
              <Col span={12}>
                <div>
                  <span className="data-labels">Order : </span>
                  <span className="data-details">{data._id}</span>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <span className="data-labels">Customer : </span>
                  <span className="data-details">{data.customerName}</span>
                </div>
              </Col>

              <Col span={12}>
                <div>
                  <span className="data-labels">Mobile: </span>
                  <span className="data-details">{data.contactNumber}</span>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <span className="data-labels">Date : </span>
                  <span className="data-details">
                    {moment.utc(Date.now()).local().format("YYYY MMM DD")}
                  </span>
                </div>
              </Col>
            </Row>
            <Divider
              type="horizontal"
              dashed
              style={{
                backgroundColor: "#000",
                opacity: 0.5,
                marginTop: 5,
                marginBottom: 5,
              }}
            />
            <div>
              <div>
                <Row gutter={[8, 24]}>
                  <Col span={8}>
                    <span className="data-labels">Details</span>{" "}
                  </Col>
                  <Col span={8}>
                    <span className="data-labels">Quantity</span>{" "}
                  </Col>
                  <Col span={8}>
                    <span className="data-labels">Price (LKR)</span>
                  </Col>
                </Row>
                {/* <span className="data-labels">Count :</span>
                    <span className="data-details">{val.count}</span> */}
              </div>
              {data.orderList.map((val) => (
                <div>
                  <Row gutter={[8, 24]}>
                    <Col span={8}>
                      {val.itemCode ? (
                        <span className="data-labels">{`${
                          val.orderType ? `${val.orderType}-` : ""
                        }${val.itemCode}`}</span>
                      ) : (
                        <span className="data-labels">
                          {!val.size &&
                          !val.frameType &&
                          !val.laminateType &&
                          !val.orderType
                            ? "Other"
                            : (val.orderType ?? "") +
                              (val.size ?? "") +
                              (val?.frameType ?? "") +
                              (val?.laminateType ?? "")}
                        </span>
                      )}
                    </Col>
                    <Col span={8}>
                      <span className="data-details">{val.count}</span>
                    </Col>
                    <Col span={8}>
                      <span className="data-details">
                        {" "}
                        {val.subTotal || val?.subtotal}
                      </span>
                    </Col>
                  </Row>
                </div>
              ))}

              <Divider
                type="horizontal"
                dashed
                style={{
                  backgroundColor: "#000",
                  opacity: 0.5,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              />
            </div>
            <div>
              <Row gutter={[8, 24]}>
                <Col span={8}>
                  <span className="data-labels" style={{ fontWeight: 500 }}>
                    Total :{" "}
                  </span>
                </Col>
                <Col span={8} />
                <Col span={8}>
                  <span className="data-details" style={{ fontWeight: 500 }}>
                    {data.total} LKR
                  </span>
                </Col>
              </Row>
            </div>
            {/* <div>
              <span className="data-labels">Total Paid Amount : </span>
              <span className="data-details">{payment} LKR</span>
            </div> */}
            <div>
              <span className="data-details">{dueNodeBill}</span>
            </div>
            <Divider
              type="horizontal"
              dashed
              style={{
                backgroundColor: "#000",
                opacity: 0.5,
                marginTop: 5,
                marginBottom: 5,
              }}
            />
            {/* {form.getFieldValue("cash") && (
              <div>
                <h4 style={{ color: "black", fontWeight: "bold" }}>Receipt</h4>
                <span className="data-labels">Date : </span>

                <span className="data-details">
                  {moment().format("DD-MM-YYYY")}
                </span>
                <br />
                <span className="data-labels">Cash : </span>
                <span className="data-labels">
                  {form.getFieldValue("cash")} LKR
                </span>
                <br />
                <span className="data-labels">Payment : </span>
                <span className="data-labels">
                  {form.getFieldValue("newPayment")} LKR
                </span>
                <br />
                <span className="data-labels">Balance : </span>
                <span className="data-labels">{change} LKR</span>
                <br />
                <Divider
                  type="horizontal"
                  dashed
                  style={{
                    backgroundColor: "#000",
                    opacity: 0.5,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                />
              </div>
            )} */}
            <div style={{ textAlign: "left" }}>
              <span className="data-labels">Thank You Come Again!</span> <br />
              <span className="data-labels">NO CASH REFUNDS</span> <br />
              <span className="data-labels">Powered by Gunarathna Studio.</span>
            </div>
            <span className="data-labels">
              Photos should be collected within 3 months.
              <br />
              After 3 months, there will be no obligation. Please keep this
              reciept safe.
            </span>
          </div>
          <div className="printBtn">
            <ReactToPrint
              trigger={() => <Button color="primary">Print Receipt</Button>}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PaymentHandler;
