export enum AuthFeatures {
  ADD_AMATEUR_ORDER,
  ADD_PROFESSIONAL_ORDER,
  ADD_PROFESSIONAL_CUSTOMER,
  ADD_PAYMENT,
  DELETE_AMATEUR_ORDER,
  DELETE_PROFESSIONAL_ORDER,
  DELETE_PROFESSIONAL_CUSTOMER,
  UPDATE_AMATEUR_ORDER,
  UPDATE_PROFESSIONAL_ORDER,
  UPDATE_PROFESSIONAL_CUSTOMER,
  VIEW_PROFESSIONAL_CUSTOMER_PROFILE,
  VIEW_PROFESSIONAL_ORDER,
  VIEW_AMATEUR_ORDER,
}

export default {
  ADMIN: [
    AuthFeatures.DELETE_AMATEUR_ORDER,
    AuthFeatures.DELETE_PROFESSIONAL_ORDER,
    AuthFeatures.DELETE_PROFESSIONAL_CUSTOMER,
    AuthFeatures.UPDATE_AMATEUR_ORDER,
    AuthFeatures.UPDATE_PROFESSIONAL_ORDER,
    AuthFeatures.UPDATE_PROFESSIONAL_CUSTOMER,
    AuthFeatures.ADD_AMATEUR_ORDER,
    AuthFeatures.ADD_PAYMENT,
    AuthFeatures.ADD_PROFESSIONAL_CUSTOMER,
    AuthFeatures.ADD_PROFESSIONAL_ORDER,
    AuthFeatures.VIEW_AMATEUR_ORDER,
    AuthFeatures.VIEW_PROFESSIONAL_CUSTOMER_PROFILE,
    AuthFeatures.VIEW_PROFESSIONAL_ORDER,
  ],
  CASHIER: [
    AuthFeatures.UPDATE_AMATEUR_ORDER,
    AuthFeatures.UPDATE_PROFESSIONAL_ORDER,
    AuthFeatures.VIEW_AMATEUR_ORDER,
    AuthFeatures.VIEW_PROFESSIONAL_ORDER,
    AuthFeatures.ADD_PAYMENT,
    AuthFeatures.ADD_PROFESSIONAL_ORDER,
    AuthFeatures.ADD_AMATEUR_ORDER,
    AuthFeatures.ADD_PROFESSIONAL_CUSTOMER,
  ],
  MANAGER: [
    AuthFeatures.DELETE_AMATEUR_ORDER,
    AuthFeatures.DELETE_PROFESSIONAL_ORDER,
    AuthFeatures.UPDATE_AMATEUR_ORDER,
    AuthFeatures.UPDATE_PROFESSIONAL_ORDER,
    AuthFeatures.VIEW_AMATEUR_ORDER,
    AuthFeatures.VIEW_PROFESSIONAL_ORDER,
    AuthFeatures.ADD_PAYMENT,
    AuthFeatures.ADD_PROFESSIONAL_ORDER,
    AuthFeatures.ADD_AMATEUR_ORDER,
    AuthFeatures.ADD_PROFESSIONAL_CUSTOMER,
  ],
};
