import { Select } from "antd";
import React from "react";

export const renderEnumOptions = (value: { [key: string]: string }) => {
  return Object.values(value).map((vl, index) => (
    <Select.Option value={vl} key={index.toString()}>
      {vl}
    </Select.Option>
  ));
};
