import React, { FC, useEffect, useState } from "react";
import { Button, Form, message, Popconfirm, Select, Space, Table } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useBusiness } from "../../services/BuisnessService";
import { useConnection } from "../../services/ApplicationContext";
import { IItemCode } from "../../services/BusinessModel";
import "./style.css";
import { ManageItemCodeModal } from "./ManageItemCodeModal";

const ItemCode: FC = () => {
  const { delete: del } = useConnection();
  const { fetchItemCodes, itemCodes } = useBusiness();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IItemCode>();
  const [visible, setVisible] = useState<boolean>(false);

  const onEdit = (record: any) => {
    setSelectedItem(record);
    setVisible(true);
  };
  const Delete = async (record: any) => {
    setLoading(true);
    try {
      const result = await del(`items/itemCode/delete?id=${record._id}`);
      if (result.status === 200) {
        await fetchItemCodes();
        message.open({
          type: "success",
          content: result.message,
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e: any) {
      console.log('error in item ', e)
      setLoading(false);
      message.open({
        type: "error",
        content: e.message,
        // content: "Error occurred",
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
    }
  };
  const columns = [
    {
      title: "ItemCodes",
      dataIndex: "_id",
      key: "itemCode",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "120px",
      render: (_: any, record: any) => {
        return (
          <Space size="middle">
            <a onClick={() => onEdit(record)}>
              <EditFilled />
            </a>
            <Popconfirm
              onConfirm={() => Delete(record)}
              title="Are you sure to delete this Item code?"
              okText="Yes"
              okButtonProps={{ danger: true }}
              cancelText="No"
              cancelButtonProps={{ type: "primary" }}
            >
              <a>
                <DeleteFilled />
              </a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    fetchItemCodes();
  }, []);

  const handleCancel = (val?: "saved") => {
    if (val && val === "saved") {
      fetchItemCodes();
    }
    setVisible(false);
    setSelectedItem(undefined);
  };
  return (
    <>
      {visible === true && (
        <ManageItemCodeModal
          handleCancel={handleCancel}
          selectedItem={undefined}
          visible={visible}
        />
      )}
      {selectedItem && (
        <ManageItemCodeModal
          handleCancel={handleCancel}
          selectedItem={selectedItem}
          visible={selectedItem !== undefined}
        />
      )}
      <div>
        <Button onClick={() => setVisible(true)} type="primary">
          Create ItemID
        </Button>
      </div>
      <div style={{ marginTop: 20 }}>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={itemCodes}
          loading={loading}
          // bordered
          style={{ width: "90%" }}
        />
      </div>
    </>
  );
};
export default ItemCode;
