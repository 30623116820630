import React, { useState, FC } from "react";
import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./Styles.css";
import { useConnection } from "../../services/ApplicationContext";
import Logo from "../../assets/logo/logo.png";
import { useUser } from "../../services/UserContext";

const Login: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { post, updateToken } = useConnection();
  const { setUserInfo, isAuthenticated } = useUser();
  // const { setRole, isAuthenticated } = useAuthZ();

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      console.log("--- ", values);
      delete values.remember;
      // username: values.username,
      //     password: values.password,
      const result = await post("users/login", values);
      console.log("login result ", result);
      if (result.status === 201) {
        const decoded: any = jwt_decode(result.data.token);

        await updateToken(result.data.token);
        setUserInfo(decoded);
        message.open({
          type: "success",
          content: "Login Success!",
          duration: 2,
          style: { textAlign: "right", marginRight: 15, marginTop: 10 },
        });
      }
      setLoading(false);
    } catch (e: any) {
      console.log("error loig ", e);
      message.open({
        type: "error",
        content: e.message,
        duration: 3,
        style: { textAlign: "right", marginRight: 15, marginTop: 10 },
      });
      setLoading(false);
    }
  };

  if (isAuthenticated()) {
    return <Redirect to="/professional" />;
  }
  return (
    <div className="main-container">
      <div className="form-container">
        <div className="form-header">
          <div className="header-container">
            <div className="detail-container">
              <h2 className="h2-login">Gunarathna Studio</h2>
              <h4 className="h4-login">Sign in to continue...</h4>
            </div>
            <div className="img-container">
              <img src={Logo} className="img-login" alt="login-ico" />
            </div>
          </div>
        </div>
        <div className="form-footer">
          <div className="form-footer-container">
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              hideRequiredMark={false}
              onFinish={onSubmit}
            >
              <Form.Item
                label="Username"
                name="userName"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input
                  prefix={
                    <UserOutlined style={{ fontSize: 12, color: "#808080" }} />
                  }
                  placeholder="username"
                  spellCheck="false"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={
                    <LockOutlined style={{ fontSize: 12, color: "#808080" }} />
                  }
                  placeholder="Password"
                  spellCheck="false"
                />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: "15px" }}
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div>
        <h5 className="powered-by">Powered by iSymple</h5>
      </div>
    </div>
  );
};

export default Login;
