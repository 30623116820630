import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  IAllOrderItem,
  IItemCode,
  ProfessionalCustomer,
} from "./BusinessModel";
import { useConnection } from "./ApplicationContext";
import { Category, IItem, ItemTypesEnum } from "../Constant/ItemModel";

export type BusinessContextProps = {
  fetchCustomers: () => void;
  fetchItemCodes: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  customers: ProfessionalCustomer[];
  allAmateurItems?: IAllOrderItem;
  allProfessionalItems?: IAllOrderItem;
  itemCodes: IItemCode[];
  setAllAmateurItems: (val: IAllOrderItem) => void;
  setAllProfessionalItems: (val: IAllOrderItem) => void;
};
const businessContext = React.createContext<{ current?: BusinessContextProps }>(
  {}
);
export type ChildrenProp = { children: ReactNode };
export const BusinessContextProvider: FC<ChildrenProp> = ({
  children,
}: ChildrenProp) => {
  // const [userInfo, setUserInfoState] = useState<UserProps>();
  const { get } = useConnection();
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [itemCodes, setItemCodes] = useState<IItemCode[]>([]);
  const [allAmateurItems, setAllAmateurItems] = useState<IAllOrderItem>();
  const [
    allProfessionalItems,
    setAllProfessionalItems,
  ] = useState<IAllOrderItem>();

  const fetchItemCodes = async () => {
    setLoading(true);
    try {
      const result = await get("items/itemCode");
      if (result.status === 200) {
        setItemCodes(result.data);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };
  const fetchAllItems = async () => {
    setLoading(true);
    try {
      const amateurItems = await get(`items/items/${Category.AMATEUR}`);
      const professionalItems = await get(
        `items/items/${Category.PROFESSIONAL}`
      );

      if (amateurItems.status === 200) {
        setAllAmateurItems({
          [ItemTypesEnum.FRAME]: amateurItems.data.filter(
            (vl: IItem) => vl.type === ItemTypesEnum.FRAME
          ),
          [ItemTypesEnum.OTHER]: amateurItems.data.filter(
            (vl: IItem) => vl.type === ItemTypesEnum.OTHER
          ),
          [ItemTypesEnum.PHOTO]: amateurItems.data.filter(
            (vl: IItem) => vl.type === ItemTypesEnum.PHOTO
          ),
          [ItemTypesEnum.LAMINATE]: amateurItems.data.filter(
            (vl: IItem) => vl.type === ItemTypesEnum.LAMINATE
          ),
        });
      }
      if (professionalItems.status === 200) {
        setAllProfessionalItems({
          [ItemTypesEnum.FRAME]: professionalItems.data.filter(
            (vl: IItem) => vl.type === ItemTypesEnum.FRAME
          ),
          [ItemTypesEnum.OTHER]: professionalItems.data.filter(
            (vl: IItem) => vl.type === ItemTypesEnum.OTHER
          ),
          [ItemTypesEnum.PHOTO]: professionalItems.data.filter(
            (vl: IItem) => vl.type === ItemTypesEnum.PHOTO
          ),
          [ItemTypesEnum.LAMINATE]: professionalItems.data.filter(
            (vl: IItem) => vl.type === ItemTypesEnum.LAMINATE
          ),
        });
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllItems();
    fetchItemCodes();
  }, []);
  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const result = await get("customer");
      if (result.status === 200) {
        setCustomers(
          result.data.map((vl: any) => ({
            ...vl,
            joinDate: moment(vl.joinDate).local().format("YYYY-MM-DD"),
          }))
        );
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };
  return (
    <businessContext.Provider
      value={{
        current: {
          fetchCustomers,
          fetchItemCodes,
          setLoading,
          setAllAmateurItems,
          setAllProfessionalItems,
          loading,
          customers,
          allAmateurItems,
          allProfessionalItems,
          itemCodes,
        },
      }}
    >
      {children}
    </businessContext.Provider>
  );
};

export default businessContext;
export const useBusiness = () => {
  const context = useContext(businessContext).current!;
  return context;
};
