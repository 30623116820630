import React, { FC, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { useConnection } from "../../services/ApplicationContext";
import { Category, IItem, ItemTypesEnum } from "../../Constant/ItemModel";
import { IAllOrderItem, IItemCode } from "../../services/BusinessModel";
import { useBusiness } from "../../services/BuisnessService";
import { checkKey } from "../../utilities/Utilities";

interface ManageItemCodeModalProps {
  visible: boolean;
  selectedItem?: IItemCode;
  handleCancel: (val?: "saved") => void;
}
export const ManageItemCodeModal: FC<ManageItemCodeModalProps> = (props) => {
  const { visible, handleCancel, selectedItem } = props;
  console.log("selel TTTTTT ", selectedItem);
  const [form] = Form.useForm();
  const { post, patch } = useConnection();
  const { allProfessionalItems, allAmateurItems } = useBusiness();
  const [loading, setLoading] = useState(false);

  const [itemID, setItemID] = useState<string>("");
  const [selectedItemList, setSelectedItemList] = useState<
    IAllOrderItem | undefined
  >(allProfessionalItems);

  const createItemID = () => {
    let newItemId = "";
    if (
      form.getFieldValue("photoSize") !== undefined &&
      form.getFieldValue("frameType") !== undefined &&
      form.getFieldValue("laminateType") !== undefined
    ) {
      newItemId = `${form.getFieldValue("photoSize")} ${form.getFieldValue(
        "frameType"
      )} ${form.getFieldValue("laminateType")}`;
      // message.success("Successfully Created!");
    } else if (
      form.getFieldValue("photoSize") !== undefined &&
      form.getFieldValue("frameType") !== undefined &&
      form.getFieldValue("laminateType") === undefined
    ) {
      newItemId = `${form.getFieldValue("photoSize")} ${form.getFieldValue(
        "frameType"
      )}`;
      // message.success("Successfully Created!");
    } else if (
      form.getFieldValue("photoSize") !== undefined &&
      form.getFieldValue("frameType") === undefined &&
      form.getFieldValue("laminateType") !== undefined
    ) {
      newItemId = `${form.getFieldValue("photoSize")} ${form.getFieldValue(
        "laminateType"
      )}`;
      // message.success("Successfully Created!");
    } else if (
      form.getFieldValue("photoSize") !== undefined &&
      form.getFieldValue("frameType") === undefined &&
      form.getFieldValue("laminateType") === undefined
    ) {
      newItemId = `${form.getFieldValue("photoSize")} P`;
      // message.success("Successfully Created!");
    } else {
      message.error("Please Fill the Form!");
    }
    if (form.getFieldValue("otherServices") !== undefined) {
      newItemId = `${newItemId} ${form
        .getFieldValue("otherServices")
        .join("-")}`;
    }
    const trimmed = newItemId?.trim();
    setItemID(trimmed);

    return trimmed;
  };
  const onReset = () => {
    form.resetFields();
    setItemID("");
  };
  const onClose = (val?: "saved") => {
    onReset();
    handleCancel(val);
  };
  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const item = createItemID();
      if (item === "") {
        message.warning(
          "Please select at least one field to generate a ItemCode",
          2
        );
      } else {
        let entry = {
          ...values,
          _id: item,
        };
        if (
          values.otherServices &&
          values.otherServices.length > 0 &&
          selectedItemList
        ) {
          const otherServices = values.otherServices.map((vl: string) => ({
            key: vl,
            value: selectedItemList[ItemTypesEnum.OTHER].find(
              (i: IItem) => i.value === vl
            )?.price,
          }));
          entry = { ...entry, otherServices };
        }
        console.log(" item id code data ", entry);
        let result;
        if (selectedItem && values.price !== undefined) {
          result = await patch("items/itemCode", {
            _id: item,
            price: values.price,
          });
        } else {
          result = await post("items/itemCode", entry);
        }

        if (result.status === 201) {
          message.open({
            type: "success",
            content: selectedItem
              ? "Item code's price updated"
              : "ItemCode Created",
            duration: 2,
            style: { textAlign: "center", marginRight: 15, marginTop: 10 },
          });
          onClose("saved");
          onReset();
        }
      }

      setLoading(false);
    } catch (e) {
      message.open({
        type: "error",
        content: selectedItem
          ? "Item code's price update failed"
          : "ItemCode create failed",
        duration: 2,
        style: { textAlign: "center", marginRight: 15, marginTop: 10 },
      });
    }
    setLoading(false);
  };

  const onCategoryChange = (val: RadioChangeEvent) => {
    if (val.target.value === Category.AMATEUR) {
      setSelectedItemList(allAmateurItems);
    }
    if (val.target.value === Category.PROFESSIONAL) {
      setSelectedItemList(allProfessionalItems);
    }
  };
  const renderSelectItemTypes = (val: ItemTypesEnum) => {
    console.log("selectedItemList ", selectedItemList);
    return (
      selectedItemList &&
      selectedItemList[val].map((vl: IItem, key: number) => (
        <Select.Option key={key.toString()} value={vl.value}>
          {vl.name}
        </Select.Option>
      ))
    );
  };
  // const renderSelectedItemPrice = (type: ItemTypesEnum, value: string) => {
  //   if (selectedItemList && value) {
  //     const price = selectedItemList[type].find((vl) => vl.value === value)
  //       ?.price;
  //     return (
  //       <div className="space-item">
  //         <span style={{ fontWeight: "bold" }}>Price: </span>
  //         <span>{price}</span>
  //       </div>
  //     );
  //   }
  // };
  const getInitialValues = () => {
    if (selectedItem && checkKey(selectedItem, "otherServices")) {
      return {
        ...selectedItem,
        otherServices: selectedItem?.otherServices?.map((vl) => vl.key),
      };
    }
    return selectedItem;
  };
  return (
    <Modal
      visible={visible}
      onCancel={() => onClose()}
      footer={null}
      title={`${selectedItem ? "Edit Item Code" : "Create Item Code ID"}`}
      centered
      width="70%"
      destroyOnClose
    >
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={getInitialValues()}
          preserve={false}
        >
          <Form.Item
            label="Category"
            name="category"
            initialValue={Category.PROFESSIONAL}
            rules={[{ required: true, message: "Please select a category" }]}
          >
            <Radio.Group
              defaultValue={Category.PROFESSIONAL}
              onChange={onCategoryChange}
            >
              <Radio value={Category.AMATEUR}>
                {Category.AMATEUR.toUpperCase()}
              </Radio>
              <Radio defaultChecked value={Category.PROFESSIONAL}>
                {Category.PROFESSIONAL.toUpperCase()}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Row>
            <Space size={60}>
              <Form.Item label="Photo Size" name="photoSize">
                <Select
                  placeholder="Select Size"
                  showSearch
                  // style={{ width: 180 }}
                  allowClear
                >
                  {renderSelectItemTypes(ItemTypesEnum.PHOTO)}
                </Select>
              </Form.Item>
              {/* <Form.Item */}
              {/*  noStyle */}
              {/*  shouldUpdate={(prevValues, currentValues) => */}
              {/*    prevValues.photoSize !== currentValues.photoSize */}
              {/*  } */}
              {/* > */}
              {/*  {({ getFieldValue }) => */}
              {/*    renderSelectedItemPrice( */}
              {/*      ItemTypesEnum.PHOTO, */}
              {/*      getFieldValue("photoSize") */}
              {/*    ) */}
              {/*  } */}
              {/* </Form.Item> */}
            </Space>
          </Row>

          <Space size={60}>
            <Form.Item label="Frame Type" name="frameType">
              <Select
                placeholder="Select Frame Type"
                showSearch
                // style={{ width: 180 }}
                allowClear
              >
                {renderSelectItemTypes(ItemTypesEnum.FRAME)}
              </Select>
            </Form.Item>
            {/* <Form.Item */}
            {/*  noStyle */}
            {/*  shouldUpdate={(prevValues, currentValues) => */}
            {/*    prevValues.frameType !== currentValues.frameType */}
            {/*  } */}
            {/* > */}
            {/*  {({ getFieldValue }) => */}
            {/*    renderSelectedItemPrice( */}
            {/*      ItemTypesEnum.FRAME, */}
            {/*      getFieldValue("frameType") */}
            {/*    ) */}
            {/*  } */}
            {/* </Form.Item> */}
          </Space>

          <Row>
            <Space size={60}>
              <Form.Item label="Laminate Type" name="laminateType">
                <Select
                  // style={{ width: 180 }}
                  placeholder="Select Laminate Type"
                  allowClear
                >
                  {renderSelectItemTypes(ItemTypesEnum.LAMINATE)}
                </Select>
              </Form.Item>

              {/* <Form.Item */}
              {/*  noStyle */}
              {/*  shouldUpdate={(prevValues, currentValues) => */}
              {/*    prevValues.laminateType !== currentValues.laminateType */}
              {/*  } */}
              {/* > */}
              {/*  {({ getFieldValue }) => */}
              {/*    renderSelectedItemPrice( */}
              {/*      ItemTypesEnum.LAMINATE, */}
              {/*      getFieldValue("laminateType") */}
              {/*    ) */}
              {/*  } */}
              {/* </Form.Item> */}
            </Space>
          </Row>
          <Row>
            <Form.Item label="Other Services" name="otherServices">
              <Select
                // style={{ width: 180 }}
                placeholder="Select Other"
                allowClear
                mode="multiple"
              >
                {renderSelectItemTypes(ItemTypesEnum.OTHER)}
              </Select>
            </Form.Item>
          </Row>
          <Form.Item label="Price" name="price">
            <InputNumber placeholder="Enter price" />
          </Form.Item>

          <Form.Item label="ItemID" name="itemIDs">
            <span>{selectedItem ? selectedItem._id : itemID}</span>
          </Form.Item>
          <Form.Item style={{ marginTop: 20 }}>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Space>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {selectedItem ? "Update price" : "Create item code"}
                </Button>
                <Button
                  htmlType="button"
                  onClick={onReset}
                  style={{ backgroundColor: "green", color: "white" }}
                  loading={loading}
                >
                  Reset
                </Button>
              </Space>
            </Col>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
