import React, { FC, useMemo } from "react";
import { Layout, Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import "./Styles.css";
import {
  BarChartOutlined,
  ControlOutlined,
  RadarChartOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";

export interface ISider {
  selectedKey?: string[];
  openKeys?: string[];
}

const SiderX: FC<ISider> = ({ selectedKey, openKeys }: ISider) => {
  const { Sider } = Layout;
  const location = useLocation();

  const SiderKeys = useMemo(() => {
    const list = location.pathname.split("/");
    return {
      selectedKeys: list.length > 2 ? list[2] : list[1],
      openKeys: list.length > 2 ? list[1] : "",
    };
  }, [location]);
  return (
    <Sider width={220} breakpoint="lg" collapsedWidth={0}>
      <Menu
        mode="inline"
        defaultSelectedKeys={selectedKey ?? [SiderKeys.selectedKeys]}
        defaultOpenKeys={openKeys ?? [SiderKeys.openKeys]}
        style={{ height: "100%" }}
      >
        <Menu.Item key="orders" style={{ marginTop: "20px", left: 0 }}>
          <NavLink to="/orders">
            <UnorderedListOutlined style={{ fontSize: "17px" }} />
            Orders
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="professional-orders"
          style={{ marginTop: "20px", left: 0 }}
        >
          <NavLink to="/professional-orders">
            <RadarChartOutlined style={{ fontSize: "17px" }} />
            Professional Orders
          </NavLink>
        </Menu.Item>
        <Menu.Item key="professional" style={{ marginTop: "20px", left: 0 }}>
          <NavLink to="/professional">
            <UsergroupAddOutlined style={{ fontSize: "17px" }} />
            Professional
          </NavLink>
        </Menu.Item>
        <Menu.Item key="adminpage" style={{ marginTop: "20px", left: 0 }}>
          <NavLink to="/adminpage">
            <ControlOutlined style={{ fontSize: "17px" }} />
            Admin Services
          </NavLink>
        </Menu.Item>
        <Menu.Item key="sales" style={{ marginTop: "20px", left: 0 }}>
          <NavLink to="/sales">
            <BarChartOutlined style={{ fontSize: "17px" }} />
            Sales
          </NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SiderX;
