import React, { FC } from "react";
import "./Styles.css";
import OrderTable from "./OrderTable";
import MainLayout from "../../components/MainLayout";

const ProfessionalOrders: FC = () => {
  return (
    <MainLayout type="primary" title="Manage Professional Orders">
      <div className="addLocations-form-container">
        <OrderTable  />
      </div>
    </MainLayout>
  );
};

export default ProfessionalOrders;
